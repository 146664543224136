<template>
  <div class="container">
    <CartFull />
  </div>
</template>

<script>
// @ is an alias to /src
import CartFull from '@/components/Cart/CartFull.vue'

export default {
  name: 'Cart',
  components: {
    CartFull
  },
  data: function () {
    return {
      
    }
  },
  mounted() {
    document.title = `${process.env.VUE_APP_TITLE} - My Cart`
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
    document.head.appendChild(recaptchaScript)
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CRY3HG283C');
  },
  methods: {


  },
}
</script>
