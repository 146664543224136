<template>
  <section>
    <!-- {{ data }} -->
 <div class="loading_wait"></div>
  </section>
</template>

<script>
  // @ is an alias to /src

  export default {
    name: "Loading",
    components: {},
    data: function() {
      return {
      };
    },
  };
</script>

<style scoped>
  .loading_wait {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background-color: #000000;
    background-image: url("/img/loadsmall.gif");
    background-size: 50px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10000000;
    opacity: 0.7;
    filter: alpha(opacity=70);
  }
  </style>
