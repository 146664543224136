<template>
  <section>
    <div class="row card-white">
      <div class="col-12 p-0">
        <span :class="`font-kitti-bold mr-0 h5 underline-${qtBu}`"
          >รายละเอียดการชำระเงิน</span
        >
      </div>
      <div class="col-12 p-0 pt-1 line-height">
        <span class="line-height">ราคาสินค้าทั้งหมด</span>
        <span class="line-height float-right">{{
          sumTOTtoLocaleString(sumTotal)
        }}</span>
        <br />
        <span class="line-height">ส่วนลดสินค้า</span>
        <span class="line-height float-right"
          >- {{ sumTOTtoLocaleString(sumDisData) }}</span
        >
        <br />
        <span class="line-height">ส่วนลดคูปอง</span>
        <span class="line-height float-right">- 0.00</span>
        <br />
        <span class="line-height font-kitti-bold h5" style="position:relative; "
          >ราคารวมสุทธิ :
          <span
            style="position: absolute; left: 0px; top: 22px; font-size:14px;"
            class="line-height"
            >(ไม่รวมค่าจัดส่ง)</span
          >
        </span>
        <span v-if="sumNetData <= 0" class="float-right lds-ellipsis"
          ><span :class="`lds-ellipsis-span-${qtBu}`"></span
          ><span :class="`lds-ellipsis-span-${qtBu}`"></span
          ><span :class="`lds-ellipsis-span-${qtBu}`"></span
          ><span :class="`lds-ellipsis-span-${qtBu}`"></span
        ></span>
        <span v-else class="line-height float-right font-price">{{
          sumTOTtoLocaleString(sumNetData)
        }}</span>
        <div class="col-12 mt-2 p-0">
          <button
            @click="goCheckout"
            :class="`mt-2 btn-PWB-nocolor bg-${qtBu} font-kitti-medium m-0`"
            style="height:40px;"
          >
            Check Out
          </button>
        </div>
      </div>
    </div>
    <Loading v-if="isLoading"/>
  </section>
</template>

<script>
  import PlppCart from "@/components/Cart/PlppCart.vue";
  import CheckStockAllCart from "@/components/Cart/CheckStockAllCart.vue";
  import OrderFormAllCart from "@/components/Cart/OrderFormAllCart.vue";
  import SalePersonAllCart from "@/components/Cart/SalePersonAllCart.vue";
  import AddCartFromSKU from "@/components/Cart/AddCartFromSKU.vue";
  import Loading from "@/components/Loading.vue";
  import TemplateAdd from "@/components/TemplateAdd.vue";
  import * as serviceAPI from "@/services/API.service";
  import * as serviceMain from "@/services/main.service";

  export default {
    name: "DetailCartB2B",
    components: {
      PlppCart,
      CheckStockAllCart,
      OrderFormAllCart,
      SalePersonAllCart,
      TemplateAdd,
      AddCartFromSKU,
      Loading
    },
    data: function() {
      return {
        isLoading: false,
        sumDisData: 0.0,
        // sumNetData:0.00,
        listRQStoreData: [],
        StatusQTSP: false,
      };
    },
    mounted() {
      this.$store.dispatch("b2b/setB2BCartOrder", {});
      // this.ListRQStore();
      // this.CheckQTSP();
    },
    props: ["sumTotal", "isHaveStocks"],
    methods: {
      sumDisReturn(para_val) {
        this.sumDisData = para_val;
      },
      sumTOTtoLocaleString(para_price) {
        return para_price.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        });
      },
      sumTheOneWithPrice(para_price) {
        let getPoint = Math.ceil(para_price / 50);
        return getPoint;
      },
      sumNET() {
        let getSumNet = this.sumTotal - this.sumDisData;
        this.sumNetData = getSumNet;
      },
      async CheckQTSP() {
        let qtsp = this.getQTSP;
        this.StatusQTSP = qtsp != 0;
      },
      async goCheckout() {
        try {
          this.isLoading = true;
          let data = {
            REF: this.getB2BCUSTREF,
            EMAIL: this.getB2BEMAIL,
            UUID: this.getB2BUUID,
          };
          let getAPI = await serviceAPI.call_API(
            "post",
            `b2b/cart/confirmOrderB2B`,
            data,
            "auth"
          );
          let getData = getAPI.data.dbitems[0];
          this.$store.dispatch("b2b/setB2BCartOrder", getData.result);
          //call api
          let postOrderCPN = await this.postOrderCPN(getData);
          if (postOrderCPN == 0) {
             this.isLoading = false;
            this.$router.push("/B2BFinished");
          }else{
            this.isLoading = false;
          }
        } catch (error) {
          this.isLoading = false;
          serviceMain.showErrorAlert(this, error);
        }
      },
      async postOrderCPN(data) {
        try {
          let getAPI = await serviceAPI.call_API(
            "post",
            `b2b/cart/postOrderCPN`,
            data,
            "auth"
          );
          // let getData = getAPI.data.dbitems[0];
          let getData = getAPI.data.dbcode;
          return getData;
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
    },
    computed: {
      getB2BUUID: function() {
        return this.$store.getters["auth/getB2BUUID"];
      },
      getB2BEMAIL: function() {
        return this.$store.getters["auth/getB2BEMAIL"];
      },
      getB2BCUSTREF: function() {
        return this.$store.getters["auth/getB2BCUSTREF"];
      },
      getQTOF: function() {
        return this.$store.getters["auth/getQTOF"];
      },
      getQTSP: function() {
        return this.$store.getters["auth/getQTSP"];
      },
      getQTSALESOURCE: function() {
        return this.$store.getters["auth/getQTSALESOURCE"];
      },
      getQTCUSTREF: function() {
        return this.$store.getters["customer/getQTCUSTREF"];
      },
      getQTDFSTORE: function() {
        return this.$store.getters["service/getQTDFSTORE"];
      },
      qtBu: function() {
        return this.$store.getters["customer/getQTBU"];
      },
      sumNetData: function() {
        return this.sumTotal - this.sumDisData || 0;
      },
      isPro: function() {
        return process.env.NODE_ENV == "production";
      },
    },
    watch: {
      sumTotal: function() {
        //this.$refs.PlppCartRef.showPLPP();
        // setTimeout(()=> this.$refs.PlppCartRef.showPLPP() , 200);
      },
    },
  };
</script>

<style scoped>
  .card-white {
    position: relative;
    background-color: white;
    border: none;
    border-radius: 8px;
    padding: 15px;
    margin: 0px 0px 10px 0px;
  }
  .head-table th {
    font-family: "kittithadabold75regular" !important;
  }
  .line-height {
    line-height: 18px !important;
  }

  /* css priceloading */
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 30px;
  }
  .lds-ellipsis span {
    position: absolute;
    top: 12px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #55246c;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis span:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis span:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis span:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis span:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  /* End css priceloading */
</style>