<template>
    
  <div class="d-flex w-100">
    <label class="containerq mr-3"> จัดสินค้า
        <input v-model="cartData.isOPSPick" type="radio" class="varckradio" value="Y" @change="changePick(cartData)">
        <span :class="`checkmark checkmark-color-${getBU}`"></span>
    </label>
    <label class="containerq"> ไม่จัดสินค้า
        <input v-model="cartData.isOPSPick" type="radio" class="varckradio" value="N" @change="changePick(cartData)">
        <span :class="`checkmark checkmark-color-${getBU}`"></span>
    </label>
  </div>
               
</template>


<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
export default {
  name: 'OPSPick',
  data: function () {
    return {
        
      }
  },
  mounted() {
    // console.log(this.cartData)
  },
  props: [
    "cartData"
  ],
  methods: {
    async changePick(e) {
      try {
        let data={
          REF:this.CartRef,
          SEQ:e.SEQ ,
          isOPSPICK:e.isOPSPick 
        }
        let api = await serviceAPI.call_API('post',`cart/setisOPSPickBySeq`,data,'auth');
        // console.log(api);
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    }
  },
  computed: {
    CartRef:function(){
      return this.$store.getters['customer/getQTCUSTREF']
    },
    getBU: function () {
      return this.$store.getters['customer/getQTBU']
    },
    
  },

}

</script>

<style lang="scss" scoped>
.newaddbox button {
    background-color: transparent;
    border-radius: 8px;
    height: 150px;
    width: 100%;
    outline: 0;
    border-style: dashed;
    border-color: gray;
}

.newaddbox .addnew {
    color: gray;
}

.container {
    min-height: 0 !important;
}

.delAddress {
    font-size: 0.8em !important;
    color: rgb(255, 255, 255);
    text-align: center;
    letter-spacing: .025em;
    border-radius: 50%;
    background-color: gray;
    width: 30px;
    height: 30px;
    line-height: 15px;
    position: absolute;
    transition: all .2s ease-out;
    right: 20px;
    padding-top: 7px;
    font-family: 'kittithadabold75regular' !important;

    &:hover {
        background-color: red;
        cursor: pointer;
    }
}

.editAddressLink {
    font-size: 0.8em !important;
    color: gray;
    text-align: center;
    letter-spacing: .025em;
    width: 30px;
    height: 30px;
    line-height: 15px;
    position: absolute;
    transition: all .2s ease-out;
    right: 60px;
    padding-top: 7px;
    font-family: 'kittithadabold75regular' !important;

    &:hover {
        color: red;
        cursor: pointer;
    }
}

.brandcard {
    color: red;
    font-weight: bold;

}

.infobox {
    margin: 5px 5px;
    padding: 20px;
    background-color: #fff;
    border-radius: 7px;
}

.labelshowaddress {
    font-family: 'kittithadabold75regular';
}

.pshowaddress {
    border-bottom: 1px solid gray;
    height: 32px;
}

.form-group {
    margin-bottom: 5px;
}

label {
    margin-bottom: 0px;
    color: rgb(125, 125, 125);
    font-size: .9em;
}

.card {
    background-color: white;
    border: none;

}

.form-control {
    height: 1.5rem;
    padding: 2px 5px;
}

.select-address {
    padding: 0px 15px;
    font-family: 'kittithadabold75regular';
    color: black;
    background-color: rgb(241, 241, 241);
    border-radius: 25px;
}

.containerq {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.containerq input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: white;
    border-radius: 50%;
    border-style: solid;
    border-color: #999999;
}

.containerq:hover input~.checkmark {
    background-color: #ccc;
}

.containerq input:checked~.checkmark {
    background-color: white;
    border-style: solid;
    // border-color: #55246c;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.containerq input:checked~.checkmark:after {
    display: block;
}

.containerq .checkmark:after {
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    // background: #55246c;

}

.varckradio {
    float: left;
}

input[err="true"] {
    border: 2px red solid !important;
}

select[err="true"] {
    border: 2px red solid !important;
}

.box-black {
    border: 2px black solid !important;
    border-radius: 8px;
}

.containerb {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.containerb input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmarkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-color: #999999;
}

/* On mouse-over, add a grey background color */
.containerb:hover input~.checkmarkbox {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerb input:checked~.checkmarkbox {
    background-color: #ffffff;
    border-style: solid;
    // border-color: #55246c;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmarkbox:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.containerb input:checked~.checkmarkbox:after {
    display: block;
}

/* Style the checkmark/indicator */
.containerb .checkmarkbox:after {
    left: 8px;
    top: 5px;
    width: 5px;
    height: 10px;
    // border: solid #55246c;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
</style>



// ALC: 0
// ALOCATE: 0
// AMT: "96.00"
// ATBCODE: "  "
// AUTOPLPP: 0
// COST: "82.80"
// CTLID: 8894900
// CanEditUPC: "N"
// EDT: ""
// FREENOSTOCK: false
// OFflag: 0
// PCD: "0000041781616"
// PREORDER: 0
// PRN: "ท่อ PVC ชั้น 5 น้ำไทย ขนาด 1 1/2 นิ้ว x 4 ม. สีฟ้า"
// PRSTATUS: "A"
// QNT: 1
// QNTAVAIL: 79
// RECID: 4317
// REF: "QT2105000014"
// REM: "Mรวม/0202 (Instore Promotion_All Store) ซื้อสินค้าใน TWD และ BNB ครบทุกๆ 3,000 บาท แลกซื้อชุดราง Led OPPLE ราคา 108 บาท (5 สิทธิ์/1 ใบเสร็จ)"
// SEQ: 1
// SERVICEFLAG: false
// SERVICEGROUP: " "
// SERVICENAME: ""
// SKCODE: 60180854
// STAT: "ยังไม่ยืนยัน\t"
// STATUS: " "
// STCODE: 920
// STOCKAVAIL: 79
// TOT: 96
// TSPGROUP: 2
// TTM: "2021-05-17T09:44:20.983Z"
// UPC: "96.00"
// WEIGHT: 1.25
// pckloc: 2
// remark: "Mรวม/0202 (Instore Promotion_All Store) ซื้อสินค้าใน TWD และ BNB ครบทุกๆ 3,000 บาท แลกซื้อชุดราง Led OPPLE ราคา 108 บาท (5 สิทธิ์/1 ใบเสร็จ)"
// slugname: "ท่อ PVC ชั้น 5 น้ำไทย ขนาด 1 1/2 นิ้ว x 4 ม. สีฟ้า"
