<template>
    
  <div class="d-flex mt-2 mb-1">
    <div class="">
      <p class="m-0">รหัสพนักงาน:</p>
    </div>
    <div class="flex-grow-1">
      <input :disabled="getQTSP == '3'" @change="setSalePersonBySeq(cartData)" v-model="cartData.SALEPERSON" type="text" class="form-control text-center w-100 saleperson-val" style="height: 33px;" placeholder="ระบุรหัสพนักงาน">
    </div>
  </div>
               
</template>


<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
export default {
  name: 'SalePerson',
  data: function () {
    return {
        listRQStoreData:[],   
      }
  },
  mounted() {
    // console.log(this.cartData)
  },
  props: [
    "cartData"
  ],
  methods: {

    async setSalePersonBySeq(e){
      try {
        let data={
          REF:this.CartRef,
          SEQ:e.SEQ ,
          SALEPERSON:e.SALEPERSON 
        }
        await serviceAPI.call_API('post',`cart/setSalePersonBySeq`,data,'auth');
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
  },
  computed: {
    CartRef:function(){
      return this.$store.getters['customer/getQTCUSTREF']
    },
    getQTSP:function(){
      return this.$store.getters['auth/getQTSP']
    },
    
  },

}

</script>

<style scoped>

</style>



// ALC: 0
// ALOCATE: 0
// AMT: "96.00"
// ATBCODE: "  "
// AUTOPLPP: 0
// COST: "82.80"
// CTLID: 8894900
// CanEditUPC: "N"
// EDT: ""
// FREENOSTOCK: false
// OFflag: 0
// PCD: "0000041781616"
// PREORDER: 0
// PRN: "ท่อ PVC ชั้น 5 น้ำไทย ขนาด 1 1/2 นิ้ว x 4 ม. สีฟ้า"
// PRSTATUS: "A"
// QNT: 1
// QNTAVAIL: 79
// RECID: 4317
// REF: "QT2105000014"
// REM: "Mรวม/0202 (Instore Promotion_All Store) ซื้อสินค้าใน TWD และ BNB ครบทุกๆ 3,000 บาท แลกซื้อชุดราง Led OPPLE ราคา 108 บาท (5 สิทธิ์/1 ใบเสร็จ)"
// SEQ: 1
// SERVICEFLAG: false
// SERVICEGROUP: " "
// SERVICENAME: ""
// SKCODE: 60180854
// STAT: "ยังไม่ยืนยัน\t"
// STATUS: " "
// STCODE: 920
// STOCKAVAIL: 79
// TOT: 96
// TSPGROUP: 2
// TTM: "2021-05-17T09:44:20.983Z"
// UPC: "96.00"
// WEIGHT: 1.25
// pckloc: 2
// remark: "Mรวม/0202 (Instore Promotion_All Store) ซื้อสินค้าใน TWD และ BNB ครบทุกๆ 3,000 บาท แลกซื้อชุดราง Led OPPLE ราคา 108 บาท (5 สิทธิ์/1 ใบเสร็จ)"
// slugname: "ท่อ PVC ชั้น 5 น้ำไทย ขนาด 1 1/2 นิ้ว x 4 ม. สีฟ้า"
