<template>
  <!-- <ImageData > -->
  <div v-if="!isLoading">
    <div
      v-for="(e, index) in listDataProject"
      :key="index"
      id="items"
      class="mb-2"
      style="width: 100%;border-top-style: solid;border-top-color: #B92A30;background-color: white;position:relative;"
    >
      <span
        class="close-circle"
        style="cursor:pointer"
        @click="deleteItem(e.PROJECT_ID)"
        >X</span
      >
      <div class="row pb-2">
        <div class="col-3 d-flex justify-content-center mt-4">
          <img
            id="image_preview"
            class="mx-auto d-block p-2"
            v-bind:src="`https:${e.IMGbase64}`"
          />
        </div>
        <div class="col-6 mt-2">
          <p class="p-0 m-0">
            <b>{{ e.TITLE ? e.TITLE : "-" }}</b>
          </p>
          <p class="p-0 m-0">
            {{ e.SUB_TITLE ? e.SUB_TITLE : "-" }}
          </p>
          <CartProdboardOrderForm v-if="getQTOF && listRQStoreData" :listRQStoreData="listRQStoreData" :cartData="e" />
          <p
            class="p-0 m-0"
            data-toggle="collapse"
            v-bind:data-target="`#collapseExample${e.PROJECT_ID}`"
            aria-expanded="false"
            v-bind:aria-controls="`collapseExample${e.PROJECT_ID}`"
          >
            <span
              style="text-decoration: underline;text-decoration-thickness: 1px;cursor: pointer;"
              >สินค้า {{ JSON.parse(e.JSNITEMS).length }} รายการ</span
            >
            <img
              style="margin-top: -4px;cursor: pointer;margin-left: 5px;"
              src="/img/prodboard/down.svg"
              width="10"
            />
          </p>
          <div class="row" v-if="e.TOTALPRICE <= 10000">
            <div class="col-12">
              <p class="p-0 m-0" style="font-size:16px">
                คิดค่าบริการ 10% จากยอดสั่งซื้อชุดครัว
              </p>
              <p class="p-0 m-0" style="font-size:14px">
                <b>ซื้อครบ 10,000.- ติดตั้งฟรี</b>
              </p>
            </div>
          </div>
        </div>
        <div class="col-3 mt-2">
          <div class="row">
            <div class="col-9">
              <div class="qty float-right mr-2">
                <span
                  class="minus bg-dark"
                  @click="minus(e.PROJECT_ID, e.SETQTY ? e.SETQTY : 1)"
                  >-</span
                >
                <input
                  type="number"
                  class="count"
                  name="qty"
                  v-bind:value="`${e.SETQTY ? e.SETQTY : 1}`"
                />
                <span
                  class="plus bg-dark"
                  @click="plus(e.PROJECT_ID, e.SETQTY ? e.SETQTY : 1)"
                  >+</span
                >
              </div>
            </div>
            <div class="col-3">
              <img
                @click="redirectProdboard(e.PROJECT_ID)"
                class="float-right mr-2"
                style="width:20px; height:20px;margin-top: 5px;cursor: pointer;"
                src="/img/prodboard/edit.svg"
                width="15%"
              />
            </div>
          </div>
          <p
            style="font-family: 'kittithadabold75regular';color: #B8292D;margin-top: 80px;"
            :class="`text-right mr-2 price-${qtBu}`"
          >
            ฿ {{ priceBath(e.TOTALPRICE) }}
          </p>
        </div>
      </div>
      <div
        class="collapse"
        v-bind:id="`collapseExample${e.PROJECT_ID}`"
        style="border-top-style: solid;border-top-color: #CCCCCC;border-top-width: thin;"
      >
        <div class="row pb-4">
          <div class="col-3">
            <p class="p-0 m-0 pl-3">
              รายการสินค้า
            </p>
            <p class="p-0 m-0 pl-3">
              {{ JSON.parse(e.JSNITEMS).length }} รายการ
            </p>
            <p class="ref p-0 m-0 pl-3">
              <b>{{ e.TITLE ? e.TITLE : "-" }}</b>
            </p>
          </div>
          <div id="prices" class="col-9">
            <div
              v-for="(el, index2) in cartData"
              :key="index2"
              style="border-bottom-style: solid;border-bottom-color: #CCCCCC;border-bottom-width: thin;"
            >
              <div class="row" v-if="el.PROJECT_ID == e.PROJECT_ID">
                <div class="col-3">
                  <ImageBySKU class="w-100" :sku="el.SKCODE" />
                </div>
                <div class="col-9">
                  <div class="row">
                    <div class="col-12">
                      <div class="pr-2">
                        <span>{{ el.PRN }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-3">
                      <span style="color: #666666;">{{ el.SKCODE }}</span>
                    </div>
                    <div class="col-4">
                      <span style="color: #666666;"
                        >จำนวน: {{ el.QNT }} ชิ้น</span
                      >
                    </div>
                    <div class="col-5">
                      <div class="pr-2 text-right">
                        <b> {{ el.UPC }}.-/ชิ้น</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as serviceAPI from "@/services/API.service";
  import * as serviceMain from "@/services/main.service";
  import ImageBySKU from "@/components/ImageBySKU.vue";
  import CartProdboardOrderForm from "@/components/Cart/CartProdboardOrderForm.vue";
  export default {
    name: "ProdboardCart",
    components: {
      ImageBySKU,
      CartProdboardOrderForm
    },
    data: function() {
      return {
        listDataProject: [],
        isLoading: true,
      };
    },
    mounted() {
      // this.showListCart();
      this.getProjectInfo();
      // console.log(this.cartData);
    },
    props: ["cartData", "CartRef", "StoreCode", "qtBu", "listRQStoreData"],
    methods: {
      async redirectProdboard(id) {
        try {
          this.$router.push({
            name: "Prodboard",
            params: { getProdID: id },
          });
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      priceBath(e) {
        return e.toLocaleString(undefined, { minimumFractionDigits: 2 });
      },
      async getProjectInfo() {
        try {
          this.isLoading = true;
          let data = {
            REF: this.CartRef,
          };
          let getAPI = await serviceAPI.call_API(
            "post",
            `prodboard/getProjectInfo`,
            data,
            "auth"
          );
          const data_items = getAPI.data.dbitems;
          if (data_items.length > 0) {
            for (var key in data_items) {
              if (data_items.hasOwnProperty(key)) {
                if (data_items[key].STCODE == this.StoreCode) {
                  // console.log(data_items[key]);
                  this.listDataProject.push(data_items[key]);
                }
              }
            }
            this.isLoading = false;
          }
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async deleteItem(id) {
        try {
          this.isLoading = true;
          let ref = this.CartRef;
          let data = {
            REF: ref,
            project_id: id,
          };
          let getAPI = await serviceAPI.call_API(
            "post",
            `prodboard/delProjectSctrans`,
            data,
            "auth"
          );
          const data_res = getAPI.data.dbitems;
          this.$parent.showListCart();
          this.listDataProject = [];
          this.getProjectInfo();
          this.isLoading = false;
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async minus(id, qty) {
        try {
          this.isLoading = true;
          let ref = this.CartRef;
          if (qty == 1) {
            console.log("delete Project");
          } else {
            qty = qty - 1;
          }
          let data = {
            REF: ref,
            project_id: id,
            qty: qty,
          };
          let getAPI = await serviceAPI.call_API(
            "post",
            `prodboard/updateProjectQty`,
            data,
            "auth"
          );
          const data_res = getAPI.data.dbitems;
          this.$parent.showListCart();
          this.listDataProject = [];
          this.getProjectInfo();
          this.isLoading = false;
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async plus(id, qty) {
        try {
          this.isLoading = true;
          qty = qty + 1;
          let ref = this.CartRef;
          let data = {
            REF: ref,
            project_id: id,
            qty: qty,
          };
          let getAPI = await serviceAPI.call_API(
            "post",
            `prodboard/updateProjectQty`,
            data,
            "auth"
          );
          const data_res = getAPI.data.dbitems;
          this.$parent.showListCart();
           this.listDataProject = [];
          this.getProjectInfo();
          this.isLoading = false;
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
    },
    computed: {
        getQTOF: function () {
            return this.$store.getters["auth/getQTOF"];
        }
      // CartRef: function() {
      //   return this.$store.getters["customer/getQTCUSTREF"];
      // },
      // StoreCode: function() {
      //   return this.$store.getters["service/getQTDFSTORE"];
      // },
      // qtBu: function() {
      //   return this.$store.getters["customer/getQTBU"];
      // },
    },
    // watch: {
    //   StoreCode: function() {
    //     this.showListCart();
    //   },
    //   ReloadCart: function() {
    //     this.isLoading = true;
    //     this.showListCart();
    //   },
    // },
  };
</script>

<style scoped>
  #image_preview {
    width: 100%;
  }
  .close-circle {
    font-size: 15px;
    color: rgb(255, 255, 255);
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.025em;
    border-radius: 50%;
    background-color: #55246c;
    width: 26px;
    height: 26px;
    line-height: 26px;
    position: absolute;
    transition: all 0.2s ease-out;
    top: -10px;
    left: -10px;
    z-index: 2;
  }
  .qty .count {
    color: #000;
    display: inline-block;
    vertical-align: top;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    padding: 0 2px;
    min-width: 35px;
    text-align: center;
  }
  .qty .plus {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    color: white;
    width: 30px;
    height: 30px;
    font: 30px/1 Arial, sans-serif;
    text-align: center;
    border-radius: 50%;
  }
  .qty .minus {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    color: white;
    width: 30px;
    height: 30px;
    font: 28px/1 Arial, sans-serif;
    text-align: center;
    border-radius: 50%;
    background-clip: padding-box;
  }

  .minus:hover {
    background-color: #55246c !important;
  }
  .plus:hover {
    background-color: #55246c !important;
  }
  /*Prevent text selection*/
  .qty span {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .qty input {
    border: 0;
    width: 2%;
  }
  .qty input::-webkit-outer-spin-button,
  .qty input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .qty input:disabled {
    background-color: white;
  }
  /* End css priceloading */
</style>
