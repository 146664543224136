<template>
  <section>
    <button @click="openModal"  type="button" class="btn-PWB-nocolor bg-black font-kitti-medium my-1`"><slot>Orderform All Cart</slot></button>
    <Modal ref="OrderformRef" size="90">

      <template v-slot:header>
        <p>Orderform All Cart</p>
      </template>
      <template v-slot:body>
        <div class="row px-3 m-0 mt-3">
          <div class="col-12 mb-2">
            <div class="row">
             
              <div class="col-3">
                <select  v-model="orderFormData.PORQ" name="PORQ" class='form-control'>
                  <option value=''>กรุณาระบุ PO/RQ</option>
                  <option v-if="allPO" value='P'>PO</option>
                  <option v-if="allRQ" value='R'>RQ</option>
                </select>
              </div>
              <div class="col-5">
                <input disabled v-show="orderFormData.PORQ =='P'" v-model="orderFormData.VENST" type="text" class="text-center form-control w-100" :placeholder="`${orderFormData.PORQ=='P'?'เลข vender หลัก':'ระบุเลข store 5 หลัก'}`">
                <Select2 v-show="orderFormData.PORQ == 'R'" v-model="orderFormData.VENST" :options="listRQStoreData" :settings="select2Settings"/>
              </div>
               <div class="col-4">
                <button @click="CheckOFVENST(orderFormData)"  class="btn-visible-black">Confirm</button>
              </div>
            </div>
          </div>
          
        
        </div>
        
      </template>
    </Modal> 
  </section>
</template>

<script>
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';
import Modal from '@/components/Modal.vue'
import Select2 from 'vue3-select2-component';
import mixin from '@/mixins/mixin.js'
export default {
  name: "OrderFormAllCart",
  data() {
    return {
      orderFormData:{
        PORQ:'',
        VENST:''
      },
      select2Settings: {
        placeholder: "ระบุ Store 5 หลัก", 
        width: "100%"
      }
    };
  },
  mixins: [mixin],
  components: {
    Modal,
    Select2
  },
  mounted() {
    
  },
  props: [
    "listRQStoreData",
    "isHaveD",
    "allPO",
    "allRQ"
  ],
  methods: {
    async openModal(){
      try {
          this.$refs.OrderformRef.showmodal()
          this.onModal()
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async onModal(){
      try {
          //ฟังชั่นทำงานเมื่อเปิด modal ให้โหลดข้อมูล จาก api
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async suggestPORQ(e){
      try {
        if(e.PORQ=='P'){
          e.VENST=''
        }else{
          e.VENST=''
        }
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async CheckOFVENST(e){
      try {
        if(e.PORQ == 'R'){
          if (e.VENST == '' || !e.VENST) {
            throw new Error('กรุณาระบุ PO/RQ ให้ครบถ้วน');
          }
          
          let data={
            PORR:e.PORQ , 
            VENST:e.VENST,
            SKU:''
          }
          await serviceAPI.call_API('post',`cart/CheckOFVENST`,data,'auth');
        }
        this.setOrderform(e)
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async setOrderform(e){
      try {
        let data={
          REF:this.CartRef,
          OF_FLAG:'1' ,
          PORR:e.PORQ , 
          VENST:e.VENST
        }
        console.log(data);
        await serviceAPI.call_API('post',`cart/setOrderform`,data,'auth');
        this.$store.dispatch('service/setReloadCart',Date.now())
        this.$refs.OrderformRef.hidemodal()
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
  
  },
  computed: {
    CartRef:function(){
      return this.$store.getters['customer/getQTCUSTREF']
    },
    qtSalesource: function() {
      return this.$store.getters['auth/getQTSALESOURCE']
    }
  },
  watch: {
  }
};
</script>

<style scoped lang="scss">
.input-History{
  border: 1px gray solid;
  padding: 0px 15px;
  border-radius:4px ;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.form-control{
  height: 33px !important;
  padding: 0.1rem 0.75rem !important;
}
</style>
