<template>
  <section>
    <button @click="openModal" v-show="getQTSP != '3'" :disabled="getQTSP == '3'" type="button" class="btn-PWB-nocolor bg-black font-kitti-medium my-1`"><slot>Sale Person All Cart</slot></button>
    <Modal ref="SalePersonRef" size="90">

      <template v-slot:header>
        <p>Sale Person All Cart</p>
      </template>
      <template v-slot:body>
        <div class="row px-3 m-0 mt-3">
          <div class="col-12 mb-2">
            <div class="row">
             
              <div class="col-4">
                <p class="m-0">รหัสพนักงาน:</p>
              </div>
              <div class="col-4">
                <input v-model="SalePersonData" type="text" class="text-center w-100" placeholder="ระบุรหัสพนักงาน">
              </div>
               <div class="col-4">
                <button @click="setSalePerson"  class="btn-visible-black">Confirm</button>
              </div>
            </div>
          </div>
          
        
        </div>
        
      </template>
    </Modal> 
  </section>
</template>

<script>
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';
import Modal from '@/components/Modal.vue'
export default {
  name: "SalePersonAllCart",
  data() {
    return {
      SalePersonData:''
    };
  },
  components: {
    Modal
  },
  mounted() {
    this.startPage()
  },
  methods: {
    async openModal(){
      try {
        let qtSP = this.getQTSP
        if(qtSP == 0) {
          throw new Error("คุณไม่มีสิทธิ์ใช้งาน Function นี้")
        }
        this.$refs.SalePersonRef.showmodal()
        this.onModal()
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async onModal(){
      try {
          //ฟังชั่นทำงานเมื่อเปิด modal ให้โหลดข้อมูล จาก api
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async setSalePerson(e){
      try {
        let qtSP = this.getQTSP
        if(qtSP == 0) {
          throw new Error("คุณไม่มีสิทธิ์ใช้งาน Function นี้")
        }
        let data={
          REF:this.CartRef,
          SALEPERSON:this.SalePersonData,
          QTSP:qtSP,
          SALESOURCE: this.getQTSALESOURCE
        }
        await serviceAPI.call_API('post',`cart/setSalePerson`,data,'auth');
        this.$store.dispatch('service/setReloadCart')
        this.$refs.SalePersonRef.hidemodal()
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async startPage(){
        let qtSP = this.getQTSP
        let qteid = this.getQTEID
      if((qtSP == 2 || qtSP == 3) && qteid != ''){
          this.setSalePersonByEID()
        }
    },

    async setSalePersonByEID(){
      try {
        let qtSP = this.getQTSP
        if(qtSP == 0) {
          throw new Error("คุณไม่มีสิทธิ์ใช้งาน Function นี้")
        }
        let data={
          REF:this.CartRef,
          SALEPERSON:this.getQTEID,
          QTSP:qtSP,
          SALESOURCE: this.getQTSALESOURCE
        }
        await serviceAPI.call_API('post',`cart/setSalePerson`,data,'auth');
        this.$store.dispatch('service/setReloadCart')
        // this.$refs.SalePersonRef.hidemodal()
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    }
  
  },
  computed: {
    CartRef:function(){
      return this.$store.getters['customer/getQTCUSTREF']
    },
    getQTSP:function(){
      return this.$store.getters['auth/getQTSP']
    },
    getQTEID:function(){
      return this.$store.getters['auth/getQTEID']
    },
    getQTSALESOURCE:function(){
      return this.$store.getters['auth/getQTSALESOURCE']
    },
    
    
  },
};
</script>

<style scoped lang="scss">
.input-History{
  border: 1px gray solid;
  padding: 0px 15px;
  border-radius:4px ;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
