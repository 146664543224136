<template>
  <section>
    <button @click="openModal" type="button" class="btn-PWB-nocolor leadtme-color font-kitti-medium my-1`">
      <slot>เพิ่มสินค้าด้วย Template</slot>
    </button>
    <Modal ref="TemplateAddToCart" size="50">

      <template v-slot:header>
        <p>เพิ่มสินค้าด้วย Template</p>
      </template>
      <template v-slot:body>
        <div id="TemplateList" class="row m-0">
            <div class="col-12 col-md-4" style="padding-top:20px">
                <!-- <h1 style="font-weight: bold;">Template</h1> -->
            </div>
            <div class="col-12 col-md-8" style="padding-top:20px">
                <div class="input-group row d-flex justify-content-end" style="float:right;">
                    <div class="col-12 col-md-9 d-flex flex-row">
                        <input v-model="dataTemplate.tosearch" type="text" class="form-control"
                            @keypress.enter="startSearch()" placeholder="ค้นหาด้วยชื่อ Template หรือ เลข Set ID ของ Template">
                        <div class="input-group-btn">
                            <button :class="`btn bg-${getBU}`" v-on:click="startSearch()">
                                <span class="fas fa-search" style="color: white;"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="row">
                    <div v-for="(e,index) in listData" :key="index" class='col-12 fillpag paddingMCard' >
                        <div class='p-0 m-1 row d-flex align-items-center' style="background-color: white;">
                          <div class="col-4 p-0 m-0">
                            <router-link to="#"> 
                                <center>
                                    <img style='width:90%;overflow:hidden;max-width:100px; height:100px' alt=""
                                        :src='e["Set Image"] != "" ? e["Set Image"] : (`${getBU}` == `bnb` ? "/img/no-imgbnb.png" : "/img/no-img.jpg")' 
                                        class='skcimage  img-responsive' onError="IMG_ERR(this)"> 
                                </center>
                            </router-link>
                          </div>
                            <div class="p-0 m-0 col-5">
                              <div class="p-0 m-0">
                                    <span id="cardid" class="brandcard" style=": 'kittithadabold65regular';">SET ID : {{e.SetId}} </span>
                                <br>
                                    <span id="cardname" class="namecard" style=": 'kittithadabold65regular';">Name : {{e["Set Name"]}}</span>
                              </div>
                            </div>
                            <div class="col-3 p-0 m-0">
                                <button :class="`bg-${getBU} bg-PWB-red buttoncart cardfont col-12`" style="color: white;" @click="checkTemplate(e.SetId)">เลือก Template</button><br>
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="row pt-1 d-flex justify-content-center">
                    <div class="col-5" style="padding: 0px 5px 0px 5px;">
                        <button @click="minus" type="button" class="d-flex justify-content-between w-100"
                            style="border-radius: 0;">
                            <p class="align-self-center m-0"><i class="fa fa-arrow-left"></i></p>
                            <p class="align-self-center m-0">ก่อนหน้านี้</p>
                        </button>
                    </div>
                    <div class="col-5 " style="padding: 0px 5px 0px 5px;">
                        <button @click="plus" type="button" class="d-flex justify-content-between w-100"
                            style="border-radius: 0;">
                            <p class="align-self-center m-0">ต่อไป</p>
                            <p class="align-self-center m-0"><i class="fa fa-arrow-right"></i></p>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center" style="padding-top: 2px;">
                        รายการคำสั่งที่ {{firstPage}}-{{lastPage}} จากทั้งหมด {{countData}} รายการ
                    </div>
                </div>
            </div>
        </div>
      </template>
    </Modal>
  </section>
</template>

<script>
  import * as serviceAPI from '@/services/API.service';
  import * as serviceMain from '@/services/main.service';
  import Modal from '@/components/Modal.vue'

  export default {
    name: "TemplateAddToCart",
    data:function() {
      return {
        TemData:[],
        dataTemplate: {
            tosearch: '',
            offset: 9,
            limit: 9
        },
        listData: [],
        countData: 0,
        nowPage: 1,
        allitem: 0,
        maxPage: 0,
        firstPage: 1,
        lastPage: 1,
        SetIdP: 0,
    
        detIsLoaded:false,
      };
    },
    components: {
      Modal,
    },

    methods: {
      async openModal() {
        try {
          this.$refs.TemplateAddToCart.showmodal()
          this.onModal()
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async hideModal() {
        try {
          this.$refs.TemplateAddToCart.hidemodal()
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async onModal() {
        try {
          //ฟังชั่นทำงานเมื่อเปิด modal ให้โหลดข้อมูล จาก api
          this.countList()
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async checkTemplate(SetId) {
        try {
          if(SetId == ''){
            return
          }
          let data = {
              "SetId": SetId
          }
          let getAPI = await serviceAPI.call_API('post', `template/detTemplate`, data, 'auth');
          if(getAPI.data.dbrows != 0){
            this.TemData = getAPI.data.dbitems;
            await this.addTemplateToCart(this.TemData,SetId)
          }
          else{
              this.TemData = []
              alert('ไม่พบรายการ err = '+getAPI.data.dbmessage)
          }
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async addTemplateToCart(e,SetId) {
        try {
          if(this.$store.getters['customer/getQTPONUM']){
            throw new Error(`PO ${this.$store.getters['customer/getQTPONUM']} ไม่สามารถเพิ่มสินค้าได้`)
          }
          for (let i = 0; i < e.length; i++) {
            let data = {
              "REF":this.getQTCUSTREF , 
              "SKU":e[i].SKC, 
              "STCODE":this.getQTDFSTORE , 
              "QNT":e[i].QNT?e[i].QNT:1
            }
            console.log(data);
            let getAPI = await serviceAPI.call_API('post', `cart/addtocart`, data,'auth');
            let getData = getAPI.data.dbitems[0]
            this.$store.dispatch('customer/setQTSHOPITEM',getData.SUMQNT)
          }
          serviceMain.showSuccessAlertWithTime(this,`เพิ่ม Template ID:${SetId} สำเร็จ`,1000)
          this.hideModal()
          // setTimeout(() => { window.location.href = `../cart`; }, 1500);
          this.$store.dispatch("service/setReloadCart")
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async startSearch(){
          this.dataTemplate.offset = 0;
          this.countList()
      },
      async showTemplate() {
          try {
              let data = this.dataTemplate
              let getAPI = await serviceAPI.call_API('post', `template/template`, data, 'auth');
              this.listData = getAPI.data.dbitems;

          } catch (error) {
              serviceMain.showErrorAlert(this, error.message)
          }
      },

      async openDetTemplate(SetId){
          this.SetIdP = SetId;
          this.detIsLoaded=true;
          await serviceMain.Delay(500)
          $('#ModalDetailTemplate').modal('show')
          this.$refs.detailTem.DetailTemplate(SetId);
      },
      async delTemplate(SetId){
          try {
              let data = { SetId: SetId}
              let getAPI = await serviceAPI.call_API('post', `template/delTemplate`, data, 'auth');
              // this.listData = getAPI.data.dbitems;
              this.showTemplate();

          } catch (error) {
              serviceMain.showErrorAlert(this, error.message)
          }
      },     
      async countList() {
          try {   
              let data = this.dataTemplate
              let getAPI = await serviceAPI.call_API('post', `template/countlist`, data, 'auth');
              this.countData = getAPI.data.dbitems[0].allitem;
              this.cal();

          } catch (error) {
              serviceMain.showErrorAlert(this, error.message)
          }
      },

      plus() {
          if (this.nowPage >= this.maxPage) {
              this.nowPage = this.maxPage;
              return;
          }
          this.nowPage = this.nowPage + 1;
          this.cal();
      },

      minus() {
          this.nowPage = this.nowPage - 1;
          if (this.nowPage <= 0) {
              this.nowPage = 1;
          }
          this.cal();
      },

      cal() {
          let countData = this.countData;
          let limit = this.dataTemplate.limit;
          let maxPage = Math.ceil(countData / limit);
          this.maxPage = maxPage;

          this.dataTemplate.offset = (this.nowPage * this.dataTemplate.limit) - this.dataTemplate.limit;
          this.showTemplate()

          this.firstPage = this.dataTemplate.offset + 1;
          this.lastPage = this.dataTemplate.offset + this.dataTemplate.limit;
      }
      
    },
    computed: {
      getBU:function(){
          return this.$store.getters['customer/getQTBU']
      },
      getQTCUSTREF:function(){
          return this.$store.getters['customer/getQTCUSTREF'];
      },
      getQTDFSTORE:function(){
          return this.$store.getters['service/getQTDFSTORE']
      },
      listdata:function(){
        return this.cardData
      },
      getQTCUSTINFO:function(){
        return this.$store.getters['customer/getQTCUSTINFO']
      },
      StoreCode:function(){
        return this.$store.getters['service/getQTDFSTORE']
        }
      

    },
    watch: {

    }
  };
</script>

<style scoped lang="scss">
  .input-History {
    border: 1px gray solid;
    padding: 0px 15px;
    border-radius: 4px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .buttoncart {
  width:100px;
  border: none;
  color: white;
  // padding: 2px 1vw;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 0px 0px;
  cursor: pointer;
  border-radius: 4px;
  font-family: 'kittithadabold75regular';
}

</style>