<template>
  <!-- <ImageData > -->
  <section class="px-2 py-0">
    <div v-if="!isLoading" class="row" :key="listData">
        <ProdboardCart :cartData="listData" :CartRef="CartRef" :StoreCode="StoreCode" :qtBu="qtBu" :listRQStoreData="listRQStoreData"/>
        <!-- bundle -->
        <div v-for="(e, index) in bundelList" :key="index" class="col-12 cardcart">
            <span :class="`close-circle-${qtBu} pointer`" @click="deleteBundle(e.data)">X</span>
            <div  class="row" v-for="(e1, index1) in e.data" :key="index1">
                <div class="col-12 "  v-if="e1.PROJECT_ID == null">
                    <div class="row">
                        <div class="col-12 col-md-3" pagco="^slugname^">
                            <router-link :to="`/ProductDetail/${replaceSlugName(e1.slugname)}`">
                                <ImageBySKU class="w-100" :sku="e1.SKCODE" />
                            </router-link>
                        </div>
                        <div class="col-12 col-md-9">
                            <span v-if="e1.CanEditUPC == 'Y'" class="font-weight-bold">
                            <textarea @change="changePriceCostName(e1)" class="changeNameText" type="text" v-model="e1.PRN"></textarea></span>
                            <span v-else class="font-900 font-weight-bold">{{ e1.PRN }}</span><br>
                            <!-- <span class="font-900 font-weight-bold">{{ e1.PRN }}</span><br> -->
                            <span><router-link :to="`/ProductDetail/${replaceSlugName(e1.slugname)}`">Show Detail</router-link></span>
                                <br>
                            <span class="font-gray font-weight-bold">รหัสสินค้า: {{ e1.SKCODE }}</span>
                            <span v-if="e1.CanEditUPC == 'Y'" class="font-gray font-weight-bold"><br>
                                ราคาปลีกรวมvat: <input @change="changePriceCostName(e1,'c')" class="changePrice" type="number" v-model="e1.NEWUPC" /> บาท</span>
                            <span v-else class="font-gray font-weight-bold"> ราคา: {{ e1.UPC }} บาท/{{ e.UNAME }}</span>
                            <span v-if="isShowCost && ( (e.JsnCOSTUpRange && e.ATBCODE == 'AV')) && !$store.getters['auth/getISTB']" class="font-gray font-weight-bold">
                                ราคาทุนไม่รวมvat: <input @change="changePriceCostName(e1,'p')" class="changePrice" type="number" v-model="e1.NEWCOST" /> บาท</span>
                            <br>
                            <span class="font-weight-bold pr-2">จำนวน</span>
                            <span class="box-qty">
                                <span @click="minus(e1)" class="text-center minus pointer">-</span>
                                <input @change="changeQNT(e1)" class="text-center input-qty" type="number" disabled v-model="e1.QNT" />
                                <span @click="add2Cart(e1)" class="text-center plus pointer">+</span>
                            </span>
                            <br />
                            <div class="d-flex justify-content-between">
                                <span class="font-weight-bold">Stock: {{ e1.STOCKAVAIL }}</span>
                                <span class="font-weight-bold">Stock DC: {{ e1.STOCKDC }}</span>
                                <span class="font-weight-bold">Status: {{ e1.PRSTATUS }}</span>

                                <span v-if="e1.loading" class="lds-ellipsis"><span :class="`lds-ellipsis-span-${qtBu}`"></span><span :class="`lds-ellipsis-span-${qtBu}`"></span><span :class="`lds-ellipsis-span-${qtBu}`"></span><span :class="`lds-ellipsis-span-${qtBu}`"></span></span>
                                <span v-else :class="` price-${qtBu}`">฿ {{ priceBath(e1.TOT) }}</span>
                            </div>
                            <OrderForm v-if="getQTOF && listRQStoreData && !getDSMMSSTORE" :listRQStoreData="listRQStoreData" :cartData="e1" />
                            <div v-show="false"><OPSPick  v-if="!e1.OFflag && !isUnusePickupNow" :cartData='e1' /></div>
                            <SalePerson v-if="StatusQTSP" :cartData="e1" />
                            <div class="row">
                                <div class="col-12">
                                    <label class="ckflagvfix" :flag="e1.SERVICEFLAG" style="font-family: 'kittithadabold75regular'; font-size: 24px; color: green;">{{ e1.SERVICENAME }}</label>
                                </div>
                            </div>
                            <label for="remark" class="font-weight-bold m-0">Remark:</label>
                            <textarea @change="changePriceCostName(e1)" class="changeNameText m-0" maxlength="100" type="text" name="remark" v-model="e1.remark"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row px-2">
                <div class="col-12 col-md-6 p-0 m-0">
                    ราคารวม<span style="text-decoration: line-through;text-decoration-thickness: 1px;">{{ e.AMT }}</span> ประหยัด <span>{{ e.DISC }}</span>
                </div>
                <div class="col-12 col-md-6 text-right p-0 m-0">
                    <span :class="`price-${qtBu}`">จ่ายเพียง <span :class="` price-${qtBu}`">{{ e.AMTBUNDLE }}</span></span>
                </div>
            </div>
      </div>
      <!-- normal -->
      <div v-for="(e, index) in nomalList" :key="index" class="col-12">
        <div v-if="e.PROJECT_ID == null" class="row">
          <div class="col-12 cardcart">
            <span :class="`close-circle-${qtBu} pointer`" @click="deleteItem(e)"
              >X</span
            >
            <div class="row">
              <div class="col-12 col-md-3" pagco="^slugname^">
                <router-link
                  :to="`/ProductDetail/${replaceSlugName(e.slugname)}`"
                >
                  <ImageBySKU class="w-100" :sku="e.SKCODE" />
                </router-link>
              </div>
              <div class="col-12 col-md-9">
                <span v-if="e.CanEditUPC == 'Y'" class="font-weight-bold">
                  <textarea
                    @change="changePriceCostName(e)"
                    class="changeNameText"
                    type="text"
                    v-model="e.PRN"
                  ></textarea
                ></span>
                <span v-else class="font-900 font-weight-bold">{{ e.PRN }}</span
                ><br />
                <span
                  ><router-link
                    :to="`/ProductDetail/${replaceSlugName(e.slugname)}`"
                    >Show Detail</router-link
                  ></span
                >
                <br />
                <span class="font-gray font-weight-bold"
                  >รหัสสินค้า: {{ e.SKCODE }}</span
                ><br v-if="isShowCost && ((e.JsnCOSTUpRange && e.ATBCODE == 'AV')) && !$store.getters['auth/getISTB']">
                <span v-if="isShowCost && ((e.JsnCOSTUpRange && e.ATBCODE == 'AV')) && !$store.getters['auth/getISTB']" class="font-gray font-weight-bold">
                  ราคาทุนไม่รวมvat:
                  <input
                    @change="changePriceCostName(e,'c')"
                    class="changePrice"
                    type="number"
                    v-model="e.NEWCOST"
                  />
                  บาท</span
                >
                <span
                  v-if="e.CanEditUPC == 'Y' && !$store.getters['auth/getISTB']"
                  class="font-gray font-weight-bold"
                ><br>
                  <!-- ราคาปลีกรวมvat<span v-if="e.JsnCOSTUpRange && isShowCost">({{ e.MinPrice }}-{{ e.MaxPrice }})</span>: -->
                  ราคาปลีกรวมvat<span v-if="e.JsnCOSTUpRange && isShowCost">(ขั้นต่ำ{{ e.MinPrice }})</span>:
                  <input
                    @change="changePriceCostName(e,'p')"
                    class="changePrice"
                    type="number"
                    v-model="e.NEWUPC"
                  />
                  บาท</span
                >
                <span v-else class="font-gray font-weight-bold">
                  ราคา: {{ e.UPC }} บาท/{{ e.UNAME }}</span
                >
                
                <br />
                <span class="font-weight-bold">จำนวน </span>
                <span class="box-qty">
                  <span @click="minus(e)" class="text-center minus pointer"
                    >-</span
                  >
                  <input
                    @change="changeQNT(e)"
                    class="text-center input-qty"
                    type="number"
                    v-model="e.QNT"
                  />
                  <span @click="plus(e)" class="text-center plus pointer"
                    >+</span
                  >
                </span>
                <br />
                <div class="d-flex justify-content-between" >
                  <span class="font-weight-bold"
                    >Stock: {{ e.STOCKAVAIL }}</span
                  >
                  <span class="font-weight-bold" v-if="!$store.getters['auth/getISTB']"
                    >Stock DC: {{ e.STOCKDC }}</span
                  >
                  <span class="font-weight-bold" v-if="!$store.getters['auth/getISTB']">Status: {{ e.PRSTATUS }}</span>

                  <span v-if="e.loading" class="lds-ellipsis"
                    ><span :class="`lds-ellipsis-span-${qtBu}`"></span
                    ><span :class="`lds-ellipsis-span-${qtBu}`"></span
                    ><span :class="`lds-ellipsis-span-${qtBu}`"></span
                    ><span :class="`lds-ellipsis-span-${qtBu}`"></span
                  ></span>
                  <span v-else :class="` price-${qtBu}`"
                    >฿ {{ priceBath(e.TOT) }}</span
                  >
                </div>
                <OrderForm
                  v-if="getQTOF && listRQStoreData && !getDSMMSSTORE"
                  :listRQStoreData="listRQStoreData"
                  :cartData="e"
                />
                <div v-show="false"><OPSPick v-if="!e.OFflag && !isUnusePickupNow" :cartData='e' /></div>
                <SalePerson v-if="StatusQTSP" :cartData="e" />
                <div class="row" v-if="e.SERVICENAME">
                  <div class="col-12">
                    <label
                      class="ckflagvfix"
                      :flag="e.SERVICEFLAG"
                      style="font-family: 'kittithadabold75regular'; font-size: 24px; color: green;"
                      >{{ e.SERVICENAME }}</label
                    >
                  </div>
                </div>
                <span class="font-weight-bold" v-if="!$store.getters['auth/getISTB']">แนะนำโปรโมชั่น: </span>
                <!-- <br /> -->
                <label v-if="!$store.getters['auth/getISTB']" for="remark" class="font-weight-bold m-0">Remark:</label>
                <textarea
                  v-if="!$store.getters['auth/getISTB']"
                  @change="changePriceCostName(e)"
                  class="changeNameText m-0 remarktext"
                  maxlength="100"
                  type="text"
                  name="remark"
                  v-model="e.remark"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div
        v-for="index in 4"
        :key="index"
        class="col-12 fillpag"
        style="padding: 5px 5px"
      >
        <div class="shadowDiv" style="overflow: hidden">
          <div class="row">
            <div class="col-12 col-md-3">
              <span class="skeleton-box w-100" style="height: 150px"></span>
            </div>
            <div class="col-12 col-md-9">
              <h3 class="blog-post__headline">
                <span class="skeleton-box" style="width: 55%"></span>
              </h3>
              <p class="m-0">
                <span class="skeleton-box" style="width: 80%"></span>
                <span class="skeleton-box" style="width: 90%"></span>
                <span class="skeleton-box" style="width: 83%"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import * as serviceAPI from "@/services/API.service";
  import * as serviceMain from "@/services/main.service";
  import ImageBySKU from "@/components/ImageBySKU.vue";
  import OrderForm from "@/components/Cart/OrderForm.vue";
  import SalePerson from "@/components/Cart/SalePerson.vue";
  import OPSPick from "@/components/Cart/OPSPick.vue";
  import ProdboardCart from "@/components/Cart/ProdboardCart.vue";
  export default {
    name: "ListCartBundle",
    components: {
      ImageBySKU,
      OrderForm,
      SalePerson,
      ProdboardCart,
      OPSPick,
    },
    data: function() {
      return {
        listData: [],
        isLoading: true,
        sumTOTData: "",
        sumCountData: 0,
        StatusQTSP: false,
        listRQStoreData: [],
        storeUnusePickupNow: ['UL', 'UC'],
        isUnusePickupNow: false,
      };
    },
    mounted() {
      // this.showListCart();
      this.ListRQStore();
      this.$emit("isHaveStock", this.isHaveStock);
      this.$emit("isHaveStatusD", this.isHaveStatusD);
      this.$emit("allPOC", this.allPO);
      this.$emit("allRQC", this.allRQ);
      this.isUnusePickupNow = this.storeUnusePickupNow.includes(this.qtSalesource) || this.storeUnusePickupNow.includes(this.StoreCode);
    },
    props: [],
    methods: {
      async CheckQTSP() { 
        let qtsp = this.getQTSP;
        this.StatusQTSP = qtsp != 0;
      },

      async showListCart() {
        try {
          setTimeout(async () => {
            let data = {
              REF: this.CartRef,
              STCODE: this.StoreCode,
              STCODESTOCK : this.$store.getters["service/getStShowStock"],
              MEMID:'0'
            };
            let getAPI = await serviceAPI.call_API(
              "post",
              "cart/getcartexceptbundle",
              data,
              "auth"
            );
            this.listData = getAPI.data.dbitems.map((e) => ({
              ...e,
              NEWUPC: e.UPC.replace(/,/g, ""),
              NEWCOST: e.COST.replace(/,/g, ""),
              MinPrice : e.JsnCOSTUpRange ? JSON.parse(e.JsnCOSTUpRange)[0].MinPrice : 0,
              MaxPrice : e.JsnCOSTUpRange ? JSON.parse(e.JsnCOSTUpRange)[0].MaxPrice : 0
            }));
            this.isLoading = false;
            this.CheckQTSP();
            this.sumTOT();
            this.getSumCount();
            this.$emit("listCart", this.listData);
            // if(this.$store.getters['auth/getISTB']){
            //   this.setOrderform()
            // }
          }, 500);
          // this.calPLPP()
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      replaceSlugName(slugname) {
        return slugname
          .replace(/ /g, "-")
          .replace(/\//g, "|")
          .replace(/#/g, "")
          .replace(/"/g, "")
          .replace(/'/g, "")
          .replace(/\?/g, "")
          .replace(/\*/g, "")
          .replace(/\+/g, "")
          .replace(/\:/g, "")
          .replace(/\./g, "");
      },
      async sumTOT() {
        try {
          let getSumTOT = 0;
          for (let e of this.listData) {
            getSumTOT = getSumTOT + e.TOT;
          }
          this.sumTOTData = getSumTOT;
          this.$emit("sumTotal", this.sumTOTData);
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },

      async changePriceCostName(e,m) {
        try {
          if(this.qtBu == 'at1' && e.JsnCOSTUpRange){
            let msg = ''
            // console.log(e.MINUP,e.MAXUP);
            if(Number(e.NEWCOST) <= 0  && this.isShowCost)
              msg = "ราคาทุนไม่รวมvatต้องมากกว่า 0"
            if((Number(e.NEWUPC) <= 0 && e.CanEditUPC == 'Y') && m =='p')
              msg = "ราคาปลีกต้องมากกว่า 0"
            // if((Number(e.MaxPrice) != 0 && Number(e.MinPrice) != 0 && e.CanEditUPC == 'Y') && m =='p'){
            //   // let NEWUPC = Math.round(e.NEWUPC*1)
            //   // let NEWCOST = Math.round(e.NEWCOST*1.1)
            //   if((Number(e.NEWUPC) < Number(e.MinPrice) || Number(e.NEWUPC) > Number(e.MaxPrice)) && m =='p'){
            //     // console.log(e.SKCODE,e.NEWUPC,Number(e.MinPrice),Number(e.NEWUPC) > Number(e.MaxPrice));
            //     // this.$store.dispatch("service/setReloadCart");
            //     msg = "ราคาปลีกไม่ตามราคาMin-Maxที่กำหนด"
            //   }
            // }
            if((Number(e.MinPrice) != 0 && e.CanEditUPC == 'Y') && m =='p'){
              // let NEWUPC = Math.round(e.NEWUPC*1)
              // let NEWCOST = Math.round(e.NEWCOST*1.1)
              if((Number(e.NEWUPC) < Number(e.MinPrice)) && m =='p'){
                // console.log(e.SKCODE,e.NEWUPC,Number(e.MinPrice),Number(e.NEWUPC) > Number(e.MaxPrice));
                // this.$store.dispatch("service/setReloadCart");
                msg = "ราคาปลีกต้องมากกว่าราคา Min ที่กำหนด"
              }
            }
            if(msg){
              this.$store.dispatch("service/setReloadCart");
              throw new Error(msg)
            }
            if(m =='c'){
              e.NEWUPC = 0
            }
          }
          let data = {
            REF: this.CartRef,
            QNT: e.QNT,
            UPC: e.NEWUPC,
            SEQ: e.SEQ,
            PRN: e.PRN,
            COST: e.NEWCOST,
            RMK: e.remark == null ? '' : e.remark ,
          };
          let getAPI = await serviceAPI.call_API(
            "post",
            `cart/changePriceCostName`,
            data,
            "auth"
          );
          this.showListCart();
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async deleteItem(e) {
        e.QNT = 0;
        this.changeQNT(e);
      },
      async deleteBundle(e) {
        for (let i = 0; i < e.length; i++) {
          e[i].QNT = 0;
          this.changeQNT(e[i]);
        }
      },
      async minus(e) {
        if (e.QNT <= 1) {
          e.QNT = 1;
        } else {
          e.QNT -= 1;
        }
        this.changeQNT(e);
      },
      async plus(e) {
        e.QNT += 1;
        this.changeQNT(e);
      },
      async changeQNT(e) {
        try {
          if (e.QNT > 999999) {
            e.QNT = 999999;
          }

          e.loading = true;
          let getCartRef = this.CartRef;
          let data = {
            REF: getCartRef,
            QNT: e.QNT,
            SEQ: e.SEQ,
          };
          let getAPI = await serviceAPI.call_API(
            "post",
            `cart/changeQNT`,
            data,
            "auth"
          );

          this.$store.dispatch("service/setReloadCart");
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async add2Cart(e) {
        try {
          e.loading = true;
          let getCartRef = this.CartRef;
          let data = {
            REF: getCartRef,
            SKU: e.SKCODE,
            STCODE: this.StoreCode,
            QNT: 1,
          };
          let getAPI = await serviceAPI.call_API(
            "post",
            `cart/addtocart`,
            data,
            "auth"
          );

          this.$store.dispatch("service/setReloadCart");
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async changeQNTBundle(e) {
        try {
          if (e.QNT > 999999) {
            e.QNT = 999999;
          }

          e.loading = true;
          let getCartRef = this.CartRef;
          let data = {
            REF: getCartRef,
            QNT: e.QNT,
            SEQ: e.SEQ,
          };
          // let getAPI = await serviceAPI.call_API("post",`cart/changeQNT`,data,"auth");
          // this.showListCart();
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async validateCart(){
        let passed = true;
        for (let i = 0; i < this.listData.length; i++) {
          if(this.qtBu == 'at1' && this.listData[i].JsnCOSTUpRange){
            // console.log(this.listData[i].SKCODE);
            if(Number(this.listData[i].NEWCOST) <= 0  && this.isShowCost){
              console.log("ราคาทุนไม่รวมvatต้องมากกว่า 0")
              passed = false
            }
            if(Number(this.listData[i].NEWUPC) <= 0 && this.listData[i].CanEditUPC == 'Y'){
              console.log("ราคาปลีกต้องมากกว่า 0")
              passed = false
            }
            // console.log(Number(this.listData[i].NEWUPC),Number(this.listData[i].MinPrice),Number(this.listData[i].MaxPrice));
            // if(this.listData[i].MaxPrice != 0 && this.listData[i].MinPrice != 0 && this.listData[i].CanEditUPC == 'Y'){
            //   if(Number(this.listData[i].NEWUPC) < Number(this.listData[i].MinPrice) || Number(this.listData[i].NEWUPC) > Number(this.listData[i].MaxPrice)){
            //     // console.log(this.listData[i].NEWUPC,Number(this.listData[i].MinPrice),Number(this.listData[i].NEWUPC) > Number(this.listData[i].MaxPrice));
            //     // this.$store.dispatch("service/setReloadCart");
            //     console.log("ราคาปลีกไม่ตามราคาMin-Maxที่กำหนด")
            //     passed = false
            //   }
            // }
            if(this.listData[i].MinPrice != 0 && this.listData[i].CanEditUPC == 'Y'){
              if(Number(this.listData[i].NEWUPC) < Number(this.listData[i].MinPrice)){
                // console.log(this.listData[i].NEWUPC,Number(this.listData[i].MinPrice),Number(this.listData[i].NEWUPC) > Number(this.listData[i].MaxPrice));
                // this.$store.dispatch("service/setReloadCart");
                console.log("ราคาปลีกต้องมากกว่าราคา Min ที่กำหนด")
                passed = false
              }
            }
            // console.log(this.listData[i].SKCODE,this.listData[i].NEWUPC,Number(this.listData[i].NEWUPC) < Number(this.listData[i].MinPrice),Number(this.listData[i].NEWUPC) > Number(this.listData[i].MaxPrice));
          }else{
            if(Number(this.listData[i].NEWUPC) <= 0 && this.listData[i].CanEditUPC == 'N'){
              console.log("ราคาปลีกต้องมากกว่า 0")
              passed = false
            }
          }
        }
        // console.log('L',passed);
        return { passed: passed }
      },
      priceBath(e) {
        return e.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        });
      },
      getSumCount() {
        let getSumCount = 0;
        for (let e of this.listData) {
          getSumCount = getSumCount + e.QNT;
        }
        this.sumCountData = getSumCount;
        this.$store.dispatch("customer/setQTSHOPITEM", getSumCount);
      },
      async ListRQStore() {
        try {
          let getAPI = await serviceAPI.call_API(
            "post",
            `cart/ListRQStore`,
            {},
            "auth"
          );
          this.listRQStoreData = getAPI.data.dbitems.map((itm) => ({
            id: itm.STCODE,
            text: `${itm.STCODE} - ${itm.ST_SCODE}`,
          }));
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      textToNumeric(txtNum) {
        let val = parseFloat(txtNum);
        val = val.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return val;
      },
      async setOrderform(){
        try {
          console.log(this.$store.getters["service/getStcShowStock"]);
          let data={
            REF:this.CartRef,
            OF_FLAG:'1' ,
            PORR:'R' , 
            VENST:this.$store.getters["service/getStcShowStock"]
          }
          await serviceAPI.call_API('post',`cart/setOrderform`,data,'auth');
        } catch (error) {
          serviceMain.showErrorAlert(this,error.message)
        }
      },
    },
    computed: {
      CartRef: function() {
        if (this.$store.getters['auth/getISB2B']) {
          return this.$store.getters["auth/getB2BCUSTREF"];
        } else {
          return this.$store.getters["customer/getQTCUSTREF"];
        }
      },
      StoreCode: function() {
        return this.$store.getters["service/getQTDFSTORE"];
      },
      ReloadCart: function() {
        return this.$store.getters["service/getReloadCart"];
      },
      getQTSP: function() {
        return this.$store.getters["auth/getQTSP"];
      },
      getQTOF: function() {
        return this.$store.getters["auth/getQTOF"];
      },
      qtBu: function() {
        return this.$store.getters["customer/getQTBU"];
      },
      getQTPROFILE: function() {
        return this.$store.getters["auth/getQTPROFILE"];
      },
      qtDfStore: function(){
        return this.$store.getters['service/getQTDFSTORE']
      },
      qtSalesource: function(){
        return this.$store.getters['auth/getQTSALESOURCE']
      },
      isShowCost: function() {
        let isShowCost = false;
        let profile = this.getQTPROFILE.split("X");
        if (profile.includes("0077")) {
          isShowCost = true;
        }
        return isShowCost;
      },
      bundelList: function() {
        let bundelLists = [];
        let tempList = [];
        let getSetId = [];
        for (let i = 0; i < this.listData.length; i++) {
          if (this.listData[i].BUNDLEGROUP != null) {
            tempList[this.listData[i].BUNDLEGROUP] = "have";
          }
        }
        for (let j = 0; j < tempList.length; j++) {
          if (tempList[j] == "have") getSetId.push(j);
        }
        for (let p = 0; p < getSetId.length; p++) {
          let gropBundel = [];
          let qnt = [];
          let AMT = 0;
          let DISC = 0;
          let AMTBUNDLE = 0;
          for (let q = 0; q < this.listData.length; q++) {
            if (getSetId[p] == this.listData[q].BUNDLEGROUP) {
              gropBundel.push(this.listData[q]);
              qnt.push(this.listData[q].QNT);
              AMT = AMT + parseFloat(this.listData[q].AMT.replace(/,/g, ""));
              DISC = DISC + this.listData[q].BUNDLEDISC;
              AMTBUNDLE = AMTBUNDLE + this.listData[q].BUNDLEAMOUNT;
            }
          }
          bundelLists.push({
            data: gropBundel,
            AMT: this.textToNumeric(AMT),
            DISC: this.textToNumeric(DISC),
            AMTBUNDLE: this.textToNumeric(AMTBUNDLE),
          });
          // bundelLists.push(gropBundel);
        }

        return bundelLists;
      },
      nomalList: function() {
        let tempList = [];
        for (let i = 0; i < this.listData.length; i++) {
          if (this.listData[i].BUNDLEGROUP == null) {
            // if(this.listData[i].JsnCOSTUpRange){
            //   let jsnprice = JSON.parse(this.listData[i].JsnCOSTUpRange)
            //   for (let j = 0; j < jsnprice.length; j++) {
            //     if(Number(this.listData[i].NEWCOST)*1.07 >= Number(jsnprice[j].MINCOST) && Number(this.listData[i].NEWCOST)*1.07 <= Number(jsnprice[j].MAXCOST)){
            //       // console.log(jsnprice[j].MINUP,jsnprice[j].MAXUP);
            //       this.listData[i].MINUP = jsnprice[j].MINUP
            //       this.listData[i].MAXUP = jsnprice[j].MAXUP
            //       this.listData[i].MinPrice = (this.listData[i].NEWCOST * jsnprice[j].MINUP * 1.07).toFixed(2)
            //       this.listData[i].MaxPrice = (this.listData[i].NEWCOST * jsnprice[j].MAXUP * 1.07).toFixed(2)
            //     }
            //   }
            // } else {
            //   this.listData[i].MINUP = 0
            //   this.listData[i].MAXUP = 0
            //   this.listData[i].MinPrice = 0
            //   this.listData[i].MaxPrice = 0
            // }
            tempList.push(this.listData[i]);
          }
        }

        return tempList;
      },
      isHaveStock: function() {
        for (let i = 0; i < this.listData.length; i++) {
          if (
            this.listData[i].STOCKAVAIL <= 0 &&
            this.listData[i].PORQ.trim() == ""
          ) {
            return false;
          }
        }
        return true;
      },
      isHaveStatusD: function() {
        for (let i = 0; i < this.listData.length; i++) {
          if ( this.listData[i].PRSTATUS == 'D' ) {
            return false;
          }
        }
        return true;
      },
      allPO: function() {
        for (let i = 0; i < this.listData.length; i++) {
          if ( this.listData[i].yesPO == '0' ) {
            return false;
          }
        }
        return true;
      },
      allRQ: function() {
        for (let i = 0; i < this.listData.length; i++) {
          if ( this.listData[i].yesRQ == '0' ) {
            return false;
          }
        }
        return true;
      },
      getDSMMSSTORE:function(){
        return this.$store.getters['service/getDSMMSSTORE']
      },
    },
    watch: {
      StoreCode: function() {
        this.showListCart();
      },
      ReloadCart: function() {
        this.isLoading = true;
        this.showListCart();
      },
      isHaveStock: function() {
        this.$emit("isHaveStock", this.isHaveStock);
      },
      isHaveStatusD: function() {
        this.$emit("isHaveStatusD", this.isHaveStatusD);
      },
      allPO: function() {
        this.$emit("allPOC", this.allPO);
      },
      allRQ: function() {
        this.$emit("allRQC", this.allRQ);
      },
    },
  };
</script>

<style lang="scss" scoped>
  $color-PWB: #55246c;
  $color-bnb: #006e63;
  $color-at1: #3866af;

  .cardcart {
    position: relative;
    background-color: white;
    border: none;
    border-radius: 8px;
    padding: 15px;
    margin: 0px 0px 10px 0px;
  }

  .shadowDiv {
    margin: 0px;
    background-color: white;
    padding: 10px;
    border-radius: 7px;
    height: auto;
  }

  .font-weight-bold {
    font-family: "kittithadabold75regular";
  }

  .minus {
    white-space: nowrap;
    width: 20px;
    height: 24px;
    border: 1px gray solid;
    border-radius: 4px 0px 0px 4px;
    padding: 2px 4px 1px 4px;
    display: inline;
    vertical-align: middle;
    text-align: right;
    font-size: 18px;
    font-weight: bold;
    z-index: 998;
  }

  .plus {
    width: 20px;
    height: 24px;
    border: 1px gray solid;
    border-radius: 0px 4px 4px 0px;
    padding: 2px 4px 1px 4px;
    display: inline;
    vertical-align: middle;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
  }

  .input-qty {
    height: 24px;
    font-weight: bold;
    width: 50px;
    text-align: center;
    font-size: 20px;
    border: 1px gray solid;

    vertical-align: text-top;
    margin: 0;
    padding: 0;
  }

  .inputEdit {
    min-width: 90px;
  }

  .btn-qty {
    border: 1px gray solid;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  @mixin close-circle($color) {
    font-size: 15px;
    color: rgb(255, 255, 255);
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.025em;
    border-radius: 50%;
    background-color: $color;
    width: 26px;
    height: 26px;
    line-height: 26px;
    position: absolute;
    transition: all 0.2s ease-out;
    top: -10px;
    left: -10px;
    z-index: 2;
  }

  .close-circle-PWB {
    @include close-circle($color-PWB);
  }

  .close-circle-bnb {
    @include close-circle($color-bnb);
  }

  .close-circle-at1 {
    @include close-circle($color-at1);
  }

  /* css priceloading */
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 30px;
  }

  .lds-ellipsis span {
    position: absolute;
    top: 12px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #55246c;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .lds-ellipsis span:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  .lds-ellipsis span:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis span:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis span:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(24px, 0);
    }
  }

  a {
    color: red;
    font-weight: bold;
  }

  a:hover {
    color: black;
  }

  .changeNameText {
    display: inline;
    width: 100%;
    height: 1.8rem;
    padding: 5px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: black;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .changePrice {
    display: inline;
    width: 20%;
    height: 30px;
    padding: 5px;
    text-align: left;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: gray;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid gray;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  /* End css priceloading */
</style>

// ALC: 0 // ALOCATE: 0 // AMT: "96.00" // ATBCODE: " " // AUTOPLPP: 0 // COST:
"82.80" // CTLID: 8894900 // CanEditUPC: "N" // EDT: "" // FREENOSTOCK: false //
OFflag: 0 // PCD: "0000041781616" // PREORDER: 0 // PRN: "ท่อ PVC ชั้น 5 น้ำไทย
ขนาด 1 1/2 นิ้ว x 4 ม. สีฟ้า" // PRSTATUS: "A" // QNT: 1 // QNTAVAIL: 79 //
RECID: 4317 // REF: "QT2105000014" // REM: "Mรวม/0202 (Instore Promotion_All
Store) ซื้อสินค้าใน TWD และ BNB ครบทุกๆ 3,000 บาท แลกซื้อชุดราง Led OPPLE ราคา
108 บาท (5 สิทธิ์/1 ใบเสร็จ)" // SEQ: 1 // SERVICEFLAG: false // SERVICEGROUP: "
" // SERVICENAME: "" // SKCODE: 60180854 // STAT: "ยังไม่ยืนยัน\t" // STATUS: "
" // STCODE: 920 // STOCKAVAIL: 79 // TOT: 96 // TSPGROUP: 2 // TTM:
"2021-05-17T09:44:20.983Z" // UPC: "96.00" // WEIGHT: 1.25 // pckloc: 2 //
remark: "Mรวม/0202 (Instore Promotion_All Store) ซื้อสินค้าใน TWD และ BNB
ครบทุกๆ 3,000 บาท แลกซื้อชุดราง Led OPPLE ราคา 108 บาท (5 สิทธิ์/1 ใบเสร็จ)" //
slugname: "ท่อ PVC ชั้น 5 น้ำไทย ขนาด 1 1/2 นิ้ว x 4 ม. สีฟ้า"
