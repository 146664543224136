<template>
  <section>
    <button @click="openModal" type="button" class="btn-PWB-nocolor leadtme-color font-kitti-medium my-1`">
      <slot>เพิ่มสินค้าด้วย SKU</slot>
    </button>
    <Modal ref="AddCartFromSKU" size="50">

      <template v-slot:header>
        <p>เพิ่มสินค้า</p>
      </template>
      <template v-slot:body>
        <div class="row px-3 m-0 mt-3">
          <div class="col-6 mb-2">
            <div class="form-group">
              <label for="SKU">SKU</label>
              <input v-model="SKUNumber" class="form-control" name="SKU" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" type = "text" maxlength = "8" placeholder="กรุณาใส่ SKU">
            </div>
          </div>
          <div class="col-6 mb-2">
            <div class="form-group">
              <label for="unit">จำนวนสินค้า</label>
              <input v-model="unit" class="form-control" name="unit" type = "number" placeholder="กรุณาใส่จำนวน">
            </div>
          </div>
          </div>
            <div class="row m-1" style="justify-content:flex-end;">
              <AddToCart :class="`bg-${getBU} btn btn-primary m-1`" style="width:100px" :sku="SKUNumber" :qnt="unit" @closeModal="hideModal()">ใส่รถเข็น</AddToCart>
              <button type="button" class="btn btn-secondary m-1" @click="hideModal"
                style="border: 1px solid black">ปิด</button>
            </div>

      </template>
    </Modal>
  </section>
</template>

<script>
  import * as serviceAPI from '@/services/API.service';
  import * as serviceMain from '@/services/main.service';
  import AddToCart from '@/components/AddToCart_Slot.vue'
  import Modal from '@/components/Modal.vue'

  export default {
    name: "AddCartFromSKU",
    data:function() {
      return {
        SKUNumber:'',
        unit:''
      };
    },
    components: {
      Modal,
      AddToCart
    },

    methods: {
      async openModal() {
        try {
          this.$refs.AddCartFromSKU.showmodal()
          this.onModal()
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async hideModal() {
        try {
          this.$refs.AddCartFromSKU.hidemodal()
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async onModal() {
        try {
          //ฟังชั่นทำงานเมื่อเปิด modal ให้โหลดข้อมูล จาก api
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      
    },
    computed: {
      getBU:function(){
          return this.$store.getters['customer/getQTBU']
      }

    },
    watch: {

    }
  };
</script>

<style scoped lang="scss">
  .input-History {
    border: 1px gray solid;
    padding: 0px 15px;
    border-radius: 4px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .buttoncart {
  width:100px;
  border: none;
  color: white;
  // padding: 2px 1vw;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 0px 0px;
  cursor: pointer;
  border-radius: 4px;
  font-family: 'kittithadabold75regular';
}

</style>