<template>
    <section>
        
            <a @click="openAddTemplate()"  class="m-0">
                 <slot> </slot>
            </a>
        
        
        <div id="ModalBuildTemplate" class="modal fade">
            <div class="modal-dialog modal-lg">

                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                        <h4 class="modal-title"></h4>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12 col-md-6">

                                <h5>image:</h5>
                                <canvas id="canvastemplate" width=100%></canvas>

                            </div>
                            <div class="col-12 col-md-6">
                                <label><strong>เลือกรูปภาพของ Template </strong></label>
                                <input type="file" id="inputtemplate" @change="handleFiles" />
                                <br><br>
                                <label><strong>ชื่อของ Template</strong></label>
                                {{dataTemplate.get_setname}}
                                <input style="width: 100%;" type="text" v-model="dataTemplate.get_setname" />
                                <br>
                                <button type="button"
                                    style="margin-top: 10px; background-color: #55246c; width: 100%; border-color: red"
                                    class="btnPWB" @click="buildtemplate()"> <strong
                                        style="font-size: 20px !important; font-family: 'kittithadamedium65regular'; color: white;">สร้าง
                                        Template</strong></button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal"
                            style="border: 1px solid black">Close</button>
                    </div>
                </div>

            </div>
        </div>
    </section>
    
</template>


<script>
    import * as serviceAPI from '../services/API.service';
    import * as serviceMain from '../services/main.service';
    export default {
        name: 'TemplateAdd',
        data: function () {
            return {
                dataTemplate: {
                    get_ref:'' , 
                    get_setname:'' , 
                    get_cruser:''
                }
            }
        },
        mounted() {
            this.handleFiles;
        },
        props: [

        ],
        methods: {  
            async openAddTemplate(){
                $('#ModalBuildTemplate').modal('show')
            },
            handleFiles(e) {
                let canvas = document.getElementById("canvastemplate");
                var ctx = canvas.getContext("2d");
                let maxW = 300;
                let maxH = 300;

                let img = new Image;
                img.onload = function () {
                    let iw = img.width;
                    let ih = img.height;
                    let scale = Math.min((maxW / iw), (maxH / ih));
                    let iwScaled = iw * scale;
                    let ihScaled = ih * scale;
                    canvas.width = iwScaled;
                    canvas.height = ihScaled;
                    ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
                    let savebase64 = canvas.toDataURL("image/jpeg", 0.5)
                    $("#inputtemplate").attr("dataimg", savebase64);
                }
                img.src = URL.createObjectURL(e.target.files[0]);
            },

            async buildtemplate() {
                try {
                    this.dataTemplate.get_ref = this.CartRef
                    if (!this.dataTemplate.get_setname) {
                        alert("กรุณากรอกชื่อ Template")
                        return
                    }

                    let base64 = $("#inputtemplate").attr("dataimg");
                    if (!base64) {
                        alert("กรุณาเลือกรูป Template")
                        return
                    }

///////////////////destructuring medthod by Pong///////////////////////////////////////////
                    let data = {...this.dataTemplate,
                                get_base: base64}
                    await serviceAPI.call_API('post', `template/addTemplate`, data, 'auth');

                    serviceMain.showSuccessAlertWithTime(this, 'บันทึกสำเร็จ', 2000)
                    this.dataTemplate.get_base = "";
                    this.dataTemplate.get_setname = "";
                    let canvas = document.getElementById('canvastemplate');
                    let context = canvas.getContext('2d');
                    this.clearCanvas(context, canvas);
                    $('#ModalBuildTemplate').modal('hide');

                    this.$emit("onSubmit")

                } catch (error) {
                    serviceMain.showErrorAlert(this, error.message)
                }
            },

            async clearCanvas(context, canvas) {
                context.clearRect(0, 0, canvas.width, canvas.height);
                var w = canvas.width;
                canvas.width = 1;
                canvas.width = w;
            }
        },
        computed: {
            CartRef:function(){
                return this.$store.getters['customer/getQTCUSTREF']
            },
        },
    }
</script>

<style scoped>
    a {
        color: black;
    }
</style>