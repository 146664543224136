<template>
  <section>
    <div v-if="isShow && qtShopItem > 0">
      <div class="row mx-0 my-3 p-0">
        <div class="col-6 p-0">
          <h5 class="font-weight-bold m-0">
            ตะกร้าของคุณ ( {{ qtShopItem || "0" }} ชิ้น )
            <a v-if="!getWSFLAG" @click="clearCart()" style="color:gray; cursor:pointer;"
              >ล้างตะกร้า</a
            >
          </h5>
          <h6 class="font-weight-bold m-0">รายการสินค้า</h6>
        </div>
        <div class="col-6 p-0">
          <h5 class="font-weight-bold text-right m-0">
            เลขตะกร้าของคุณ ( {{ getRef }} )<span v-if="getCopyRef">( {{ getCopyRef }} )</span>
          </h5>
          <h6 class="font-weight-bold text-right m-0">
            <span v-if="!$store.getters['auth/getISTB']">{{ getBuName }} สาขา {{ getStoreName }} ({{ getStore }})</span>
            <span v-else> {{ $store.getters["customer/getEPCUSTINFO"].STNAME }} ({{ $store.getters["service/getStcShowStock"] }})</span>
          </h6>
          <h6 v-if="getCreditBalance" class="font-weight-bold text-right m-0"> 
            CreditBalance {{ getCreditBalance }}
          </h6>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-12 col-md-8">
          <ListCartBundle
            ref="ListCartRef"
            @sumTotal="sumTotal"
            @isHaveStock="isHaveStock"
            @isHaveStatusD="isHaveStatusD"
            @allPOC="allPOC"
            @allRQC="allRQC"
            @listCart="listCart"
            v-if="!$store.getters['auth/getISB2B']"
          />
          <ListCart
            ref="ListCartRef"
            @sumTotal="sumTotal"
            @isHaveStock="isHaveStock"
            v-else
          />
        </div>
        <div v-if="!$store.getters['auth/getISB2B']" class="col-12 col-md-4 ">
          <DetailCart
            ref="DetailCartRef"
            :sumTotal="sumTOT"
            :isHaveStocks="isHaveStocks"
            :isHaveD="isHaveD"
            :allRQ="allRQ"
            :allPO="allPO"
            :listData="listData"
            @validatePrice="validatePrice" 
            :verifyPrice="verifyPrice"
            :key="getReloadStore"
          />
        </div>
         <div v-else class="col-12 col-md-4 ">
          <DetailCartB2B
            ref="DetailCartRef"
            :sumTotal="sumTOT"
            :isHaveStocks="isHaveStocks"
            :key="getReloadStore"
          />
        </div>
      </div>
    </div>
    <!-- <div v-else>
      <CartFullNotFoundProduct />
    </div> -->
  </section>
</template>

<script>
  // @ is an alias to /src
  import ListCart from "@/components/Cart/ListCart.vue";
  import DetailCart from "@/components/Cart/DetailCart.vue";
  import DetailCartB2B from "@/components/Cart/DetailCartB2B.vue";
  import CartFullNotFoundProduct from "@/components/Cart/CartFullNotFoundProduct.vue";
  import * as serviceAPI from "@/services/API.service";
  import * as serviceMain from "@/services/main.service";
  import ListCartBundle from "@/components/Cart/ListCartBundle.vue";

  export default {
    name: "CartFull",
    components: {
      ListCart,
      DetailCart,
      CartFullNotFoundProduct,
      ListCartBundle,
      DetailCartB2B
    },
    data: function() {
      return {
        sumTOT: 0,
        isShow: true,
        isHaveStocks: false,
        isHaveD: false,
        allPO: true,
        allRQ: true,
        listData:[],
        verifyPrice:false
      };
    },
    mounted() {
      this.initPage();
    },
    methods: {
      async initPage() {
        this.isShow = this.qtShopItem > "0";
        if (this.qtShopItem <= "0") {
          this.qtDlvType = "";
        } else {
          this.$refs.ListCartRef.showListCart();
        }
        this.DisMemberCode = 0;
        this.DisMemberName = '';
      },
      async sumTotal(val) {
        this.sumTOT = val;
        // this.$refs.DetailCartRef.showListCart()
      },
      async isHaveStock(val) {
        this.isHaveStocks = val;
        // this.$refs.DetailCartRef.showListCart()
      },
      async isHaveStatusD(val) {
        this.isHaveD = val;
        // this.$refs.DetailCartRef.showListCart()
      },
      async allPOC(val) {
        this.allPO = val;
      },
      async allRQC(val) {
        this.allRQ = val;
      },
      async listCart(val) {
        this.listData = val;
      },
      async clearCart() {
        try {
          this.$swal
            .fire({
              html: "<h2>ยกเลิกรายการทั้งหมด</h2>",
              showCancelButton: true,
              confirmButtonColor: this.getBuColor,
              confirmButtonText: "ตกลง",
            })
            .then(async (result) => {
              if (result.value) {
                let data = {
                  REF: this.getRef,
                };
                let getAPI = await serviceAPI.call_API(
                  "post",
                  `cart/clearCart`,
                  data,
                  "auth"
                );
                this.qtShopItem = 0;
                if(this.$store.getters['auth/getISTB']){
                  this.getRefB2B();
                } else {
                  this.genNewCartRef();
                }
              }
            });
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async genNewCartRef() {
        let data = {
          MCID: this.$store.getters["customer/getQTMCID"],
          EMAIL: this.getQTCUSTINFO.EMAIL,
          MOBILE: this.getQTCUSTINFO.MOBILE,
          USERID: this.$store.getters["auth/getQTUSERID"],
        };
        let getAPI = await serviceAPI.call_API(
          "post",
          "user/getCustref",
          data,
          "auth"
        );
        let getData = getAPI.data.dbitems[0];
        let getCUSTREF = getData.CUSTREF;
        this.$store.dispatch("customer/setQTCUSTREF", getCUSTREF);
        this.$store.dispatch('customer/setQTCOPYREF', "")
        this.$store.dispatch('customer/setQTCREDITBAL', "")
        this.$store.dispatch("customer/setQTDLVTRTYPE", "");
      },
      async validatePrice(){
        this.verifyPrice = await this.$refs.ListCartRef.validateCart();
        // console.log('F',this.verifyPrice);
        // return this.verifyPrice
      },
      async getRefB2B() {
            try {
                // console.log('get ref B2B');
                
                let data = {
                    USERNAME: this.$store.getters['auth/getUSERMAILB2B'],
                    MCID: this.$store.getters['customer/getQTMCID'],
                };
                let getAPI = await serviceAPI.call_API('post', `directsale/getCustRefB2B`, data);
                let getData = getAPI.data.dataRef.dbitems[0];
                // console.log(getData);
                let datainfo = getAPI.data.dataCusInfo.dbitems[0];
                // console.log(datainfo);
                let dataCusDS = getAPI.data.dataCusDS
                // console.log(dataCusDS);
                let getCUSTREF = getData.CUSTREF;
                let getNITEM = getData.SUMQNT;
                let getCURSTC = getData.CURSTC.trim();
                // console.log(this.$store.getters['auth/getUser']);
                this.$store.dispatch('customer/setQTCUSTINFO', {
                    CNAME: datainfo.CNAME,
                    MOBILE: datainfo.MOBILE,
                    EMAIL: datainfo.EMAIL,
                    T1C: datainfo.T1C,
                    VIPID: datainfo.VIPID,
                    SALESOURCE: this.$store.getters['auth/getUser'].QTSALESOURCE,
                    EMPLOYEEBUSHORTCODE: '',
                    EMPLOYEEID: '',
                    ISSTAFF: '',
                })
                this.$store.dispatch('customer/setEPCUSTINFO', dataCusDS)
                this.$store.dispatch('service/setStcShowStock',dataCusDS.STORE)
                // console.log(dataCusDS.STORE);
                this.$store.dispatch('customer/setQTCUSTREF', getCUSTREF)
                this.$store.dispatch('customer/setQTSHOPITEM', getNITEM)
                if (getCURSTC) {
                    this.$store.dispatch('service/setQTDFSTORE', getCURSTC)
                    let getAPI = await serviceAPI.call_API('post', 'service/findBU', {
                        "STCODE": getCURSTC
                    }, 'auth');
                    let getBU = getAPI.data.dbitems[0].BU;
                    this.$store.dispatch('customer/setQTBU', getBU)

                    let getQTSAVESTORE = this.$store.getters['service/getQTSAVESTORE']
                    let locationsTEMP = getQTSAVESTORE.split('|');
                    let locations = locationsTEMP.map(e => e.split(','));
                    let getQTStoreNow = getCURSTC
                    let QTStoreName = locations.filter(e => e[0] == getQTStoreNow);
                    let getStoreName = QTStoreName[0][1];
                    this.$store.dispatch('service/setQTSTORENAME', getStoreName)
                } else {
                    if(!this.qtDfStore) {
                        $('#mapModal').modal('show')
                    }
                }
                this.$router.push('/');
                
            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
        },
    },
    computed: {
      getRef: function() {
        if (this.$store.getters["auth/getISB2B"]) {
          return this.$store.getters["auth/getB2BCUSTREF"];
        } else {
          return this.$store.getters["customer/getQTCUSTREF"];
        }
      },
      getCopyRef:function(){
          return this.$store.getters['customer/getQTCOPYREF']
      },
      getCreditBalance:function(){
          return this.$store.getters['customer/getQTCREDITBAL']
      },
      getStore: function() {
        return this.$store.getters["service/getQTDFSTORE"];
      },
      getStoreName: function() {
        return this.$store.getters["service/getQTSTORENAME"];
      },
      getBuName: function() {
        let getBU = this.$store.getters["customer/getQTBU"];
        if (getBU == "bnb") {
          return "บ้านแอนด์บียอนด์";
        } else if (getBU == "at1") {
          return "Auto1";
        } else {
          return "ไทวัสดุ";
        }
      },
      getBuColor: function() {
        let color = "";
        switch (this.qtBu) {
          case "bnb":
            color = "#006e63";
            break;
          case "at1":
            color = "#3866af";
            break;
          default:
            color = "#55246c";
        }
        return color;
      },
      qtShopItem: {
        get() {
          return this.$store.getters["customer/getQTSHOPITEM"];
        },
        set(value) {
          this.$store.dispatch("customer/setQTSHOPITEM", value);
        },
      },
      qtBu: function() {
        return this.$store.getters["customer/getQTBU"];
      },
      qtDlvType: {
        get() {
          return this.$store.getters["customer/getQTDLVTRTYPE"] || "";
        },
        set(value) {
          this.$store.dispatch("customer/setQTDLVTRTYPE", value);
        },
      },
      getQTCUSTINFO: function() {
        return this.$store.getters["customer/getQTCUSTINFO"];
      },
      isPro: function() {
        return process.env.NODE_ENV == "production";
      },
      getReloadStore:function(){
        return this.$store.getters['service/getReloadStore']
      },
      DisMemberCode: {
            get () {
                return this.$store.getters['service/getDisMemberCode']
            },
            set (value) {
                this.$store.dispatch('service/setDisMemberCode',value)
            }
      },
      DisMemberName: {
            get () {
                return this.$store.getters['service/getDisMemberName']
            },
            set (value) {
                this.$store.dispatch('service/setDisMemberName',value)
            }
      },
      getWSFLAG: function () {
        return this.$store.getters['auth/getWSFLAG']
      },
    },
    watch: {
      // getStore: function() {
      //   this.initPage();
      // },
      getReloadStore: function() {
        location.reload(true);
        // this.initPage()
      },
      // qtShopItem: function () {
      //   this.initPage()
      // }
    },
  };
</script>

<style scoped>
  .font-weight-bold {
    font-family: "kittithadabold75regular";
  }
</style>
