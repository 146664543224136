<template>
   
    <section v-if ="isHavePlpp">
      <div class="row">
       <div class="col-12 p-0 overflow-auto">
         <span class="font-kitti-bold mr-0 h6 underline-PWB" >ข้อมูลส่วนลด</span>
         <table class="m-0 w-100 ">
            <tr class="font-light-gray head-table">
              <th>Product</th>
              <th class="text-center">Qty</th>
              <th class="text-center">Disc</th>
            </tr>
            <tr v-for="e in listData" :key="e.SEQ" >
              <td class="text-truncate" style='max-width:80px'>{{e.SKNAME}}</td>
              <td class="text-center">{{e.QNT}}</td>
              <td class="text-center">{{e.DSC}}</td>
            </tr>

          </table>

       </div>
        <div class="col-12 p-0">
          <hr style="margin:0px; margin-top:7px; margin-bottom:7px;">
        </div>
      </div>
      
    </section>

</template>


<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
export default {
  name: 'PlppCart',
  components: {
  
  },
  data: function () {
    return {
        listData:[],
        isHavePlpp: false,
        sumDisPlpp:0
      }
  },
  mounted() {
    this.showPLPP()
  },
  props: [
 
  ],
  methods: {
    async showPLPP(){
      try {
        
        let data={
          REF: this.getRef, 
          STC:this.getStore
        }
        let getAPI = await serviceAPI.call_API('post',`cart/PLPP`,data,'auth');
        this.isHavePlpp = getAPI.data.dbrows == 0 ? false:true;
        this.listData = getAPI.data.dbitems;
        this.sumDis()
        
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async sumDis(){
      try {
        let getSumDis = 0
        for(let e of this.listData){
          getSumDis = getSumDis + e.DSC
        }
        this.sumDisPlpp = getSumDis;
        this.$emit('sumDisReturn',this.sumDisPlpp)
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    

  },
  computed: {
      getRef:function(){
        return this.$store.getters['customer/getQTCUSTREF']
      },
      getStore:function(){
        return this.$store.getters['service/getQTDFSTORE']
      },
      getReloadStore:function(){
        return this.$store.getters['service/getReloadStore']
      },
    },
    watch:{
      // getStore:function(){
      //   setTimeout(()=> this.showPLPP() , 2000);
      // },
      getReloadStore:function(){
        // setTimeout(()=> this.showPLPP() , 2000);
      }
    }

}

</script>

<style scoped>
  .head-table th{
    font-family: 'kittithadabold75regular' !important;
  }

</style>



// ALC: 0
// ALOCATE: 0
// AMT: "96.00"
// ATBCODE: "  "
// AUTOPLPP: 0
// COST: "82.80"
// CTLID: 8894900
// CanEditUPC: "N"
// EDT: ""
// FREENOSTOCK: false
// OFflag: 0
// PCD: "0000041781616"
// PREORDER: 0
// PRN: "ท่อ PVC ชั้น 5 น้ำไทย ขนาด 1 1/2 นิ้ว x 4 ม. สีฟ้า"
// PRSTATUS: "A"
// QNT: 1
// QNTAVAIL: 79
// RECID: 4317
// REF: "QT2105000014"
// REM: "Mรวม/0202 (Instore Promotion_All Store) ซื้อสินค้าใน TWD และ BNB ครบทุกๆ 3,000 บาท แลกซื้อชุดราง Led OPPLE ราคา 108 บาท (5 สิทธิ์/1 ใบเสร็จ)"
// SEQ: 1
// SERVICEFLAG: false
// SERVICEGROUP: " "
// SERVICENAME: ""
// SKCODE: 60180854
// STAT: "ยังไม่ยืนยัน\t"
// STATUS: " "
// STCODE: 920
// STOCKAVAIL: 79
// TOT: 96
// TSPGROUP: 2
// TTM: "2021-05-17T09:44:20.983Z"
// UPC: "96.00"
// WEIGHT: 1.25
// pckloc: 2
// remark: "Mรวม/0202 (Instore Promotion_All Store) ซื้อสินค้าใน TWD และ BNB ครบทุกๆ 3,000 บาท แลกซื้อชุดราง Led OPPLE ราคา 108 บาท (5 สิทธิ์/1 ใบเสร็จ)"
// slugname: "ท่อ PVC ชั้น 5 น้ำไทย ขนาด 1 1/2 นิ้ว x 4 ม. สีฟ้า"
