<template>
   
    <section>
      <div class="row card-white">
        
        <div class="col-12 p-0">
          <span :class="`font-kitti-bold mr-0 h5 underline-${qtBu}`" >รายละเอียดการชำระเงิน</span>
        </div>
        <div class="col-12 p-0 pt-1 line-height">
          <span class="line-height">ราคาสินค้าทั้งหมด</span>
          <span class="line-height float-right">{{sumTOTtoLocaleString(sumTotal)}}</span>
          <br>
          <span v-show="!$store.getters['auth/getISTB']" class="line-height">ส่วนลดสินค้า</span>
          <span v-show="!$store.getters['auth/getISTB']" class="line-height float-right">- {{sumTOTtoLocaleString(sumDisData)}}</span>
          <br>
          <span v-show="!$store.getters['auth/getISTB']" class="line-height">ส่วนลดคูปอง</span>
          <span v-show="!$store.getters['auth/getISTB']" class="line-height float-right">- 0.00</span>
          <br>
          <span class="line-height font-kitti-bold h5" style="position:relative; ">ราคารวมสุทธิ : 
            <span style="position: absolute; left: 0px; top: 22px; font-size:14px;" class="line-height">(ไม่รวมค่าจัดส่ง)</span>
          </span>
          <span v-if="sumNetData<=0"  class="float-right lds-ellipsis"><span :class='`lds-ellipsis-span-${qtBu}`'></span><span :class='`lds-ellipsis-span-${qtBu}`'></span><span :class='`lds-ellipsis-span-${qtBu}`'></span><span :class='`lds-ellipsis-span-${qtBu}`'></span></span>
          <span v-else class="line-height float-right font-price">{{sumTOTtoLocaleString(sumNetData)}}</span>
        
          

       </div>
        <div class="col-12 p-0 pt-2">
          <hr style="margin:0px; margin-top:7px; margin-bottom:7px;">
        </div>
        <div v-show="!$store.getters['auth/getISTB']" class="col-12">
          <PlppCart ref="PlppCartRef" @sumDisReturn="sumDisReturn"/>
        </div>
        <div v-show="!$store.getters['auth/getISTB']" class="col-12 p-0">
          <img src="@/assets/img/the1s.png" class="mr-1" style="height:35px;" >
          <span class="font-kitti-medium" >รับคะแนนสะสม The1 ทั้งหมด 
            <span id="theonepoint" class="font-kitti-medium" >{{sumTheOneWithPrice(sumNetData)}}</span>
            คะแนน 
          </span> 
        </div>
        <div v-show="!$store.getters['auth/getISTB']" class="col-12 p-0">
          <hr style="margin:0px; margin-top:7px; margin-bottom:7px;">
        </div>
        <div v-if="!$store.getters['auth/getISTB']" class="col-12 mt-2 p-0">
          <button v-if="!isLoading" @click="goCheckout" :class="`btn-PWB-nocolor bg-${qtBu} font-kitti-medium m-0`">Check Out</button>
          <button v-else :class="`btn-PWB-nocolor skeletoncart-box font-kitti-medium m-0`">Check Out</button>
          <AddCartFromSKU class="my-1 " >เพิ่มสินค้าด้วย SKU</AddCartFromSKU>
          <TemplateAddToCart class="my-1 " >เพิ่มสินค้าด้วย Template</TemplateAddToCart>
        </div>
        <div v-else class="col-12 mt-2 p-0">
          <button v-if="!isLoading" @click="goCheckout" :class="`btn-PWB-nocolor bg-${qtBu} font-kitti-medium m-0`">Check Out</button>
          <button v-else :class="`btn-PWB-nocolor skeletoncart-box font-kitti-medium m-0`">Check Out</button>
        </div>
        <div v-if="!$store.getters['auth/getISTB']" class="col-12 p-0">
          <hr style="margin:0px; margin-top:7px; margin-bottom:7px;">
        </div>
        <div v-if="!$store.getters['auth/getISTB']" class="col-12 mt-2 p-0">
          <CheckStockAllCart class="my-1">เช็คสต็อก</CheckStockAllCart>
          <OrderFormAllCart v-if="getQTOF && !getDSMMSSTORE" class="my-1" :listRQStoreData="listRQStoreData" :isHaveD="isHaveD" :allRQ="allRQ" :allPO="allPO">Orderform All Cart</OrderFormAllCart>
          <SalePersonAllCart v-if="StatusQTSP" class="my-1">Sale Person All Cart</SalePersonAllCart>
          <TemplateAdd><button  class="btn-PWB-nocolor leadtme-color font-kitti-medium m-0">สร้าง Template</button></TemplateAdd>
        </div>
      </div>

      <!-- <span style="margin-right:0px; font-family: 'kittithadabold75regular'; font-size:26px;">ราคาสินค้าทั้งหมด : </span>
      <label class="numeric" style=" float:right; font-family: 'markerfeltwide-plainregular'; font-size:26px;" originalprize="436604" id="prizetotheone">436,604.00</label>
      <br>
      <span style="margin-right:0px;font-family: 'kittithadamedium65regular';  font-size:14px; position: absolute; left: 30px; top: 60px; z-index: 1;">(ราคานี้รวมส่วนลด PLPP แล้ว แต่ยังไม่รวมค่าจัดส่ง) </span>


      <hr style="margin:0px; margin-top:15px; margin-bottom:10px;">

      <img src="@/assets/img/the1s.PNG" style="height:35px;" alt="">
      <span class="" style="font-family: 'kittithadamedium65regular';">รับคะแนนสะสม The1 ทั้งหมด <span id="theonepoint" style="font-family: 'kittithadamedium65regular';">8732</span> คะแนน </span> 
      <a style="margin-top:20px;margin-bottom:10px; background-color:#55246c ;border-radius: 5px;padding:4px; height:30px; border: none;" ;="" class="btn twdorbnb btn-primary btn-block btnheaderdetail">
      <span class="" style="font-family: 'kittithadamedium65regular';">ชำระเงิน</span></a>
      <a style="margin-top:20px;margin-bottom:10px; background-color:black ;border-radius: 5px;padding:4px; height:30px; border: none;" ;="" class="btn btn-primary btn-block btnheaderdetail">
      <span class="" style="font-family: 'kittithadamedium65regular';">เช็คสต็อก</span></a>
      <a  style="margin-top:20px;margin-bottom:10px; background-color:gray ;border-radius: 5px;padding:4px; height:30px; border: none;" ;="" class="btn btn-primary btn-block btnheaderdetail">
      <span class="" style="font-family: 'kittithadamedium65regular';">สร้าง Template</span></a>


      <div id="typeplpp" style="display: none;">
      <span style="color:black;font-family: 'kittithadabold75regular';">ข้อมูลส่วนลด</span>
      <table id="bonusTab" style="width:100%; margin-bottom:70px;">
      <thead bgcolor="silver">
      <tr><th>SEQ</th><th>Product</th><th>Quant</th><th>Disc</th><th>Remark
      </th></tr></thead>
      <tbody><tr><td colspan="5"><div id="noplpp">--ไม่พบ PLPP --</div></td></tr>
      </tbody></table>


      </div>
      <hr style="position: absolute;bottom: 60px; z-index: 2; width: 85%; ">
      <span style="margin-right:0px;font-family: 'kittithadamedium65regular';  font-size:18px; position: absolute; left: 35%;bottom: 68px; z-index: 4;">ยังมีสินค้าอีกมากมาย </span>
      <a style=" position: absolute;bottom: 20px; z-index: 2; width: 85%;margin-top:20px;margin-bottom:10px; background-color:black ;border-radius: 5px;padding:4px; height:30px; border: none;" ;="" class="btn twdorbnb btn-primary btn-block btnheaderdetail">
      <span class="" style="font-family: 'kittithadamedium65regular';">ช้อปต่อ</span></a> -->
    </section>

</template>


<script>

import PlppCart from '@/components/Cart/PlppCart.vue'
import CheckStockAllCart from '@/components/Cart/CheckStockAllCart.vue'
import OrderFormAllCart from '@/components/Cart/OrderFormAllCart.vue'
import SalePersonAllCart from '@/components/Cart/SalePersonAllCart.vue'
import AddCartFromSKU from '@/components/Cart/AddCartFromSKU.vue'
import TemplateAddToCart from '@/components/Cart/TemplateAddToCart.vue';
import TemplateAdd from '@/components/TemplateAdd.vue';
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from "@/services/main.service";

export default {
  name: 'DetailCart',
  components: {
    PlppCart,
    CheckStockAllCart,
    OrderFormAllCart,
    SalePersonAllCart,
    TemplateAdd,
    AddCartFromSKU,
    TemplateAddToCart
  },
  data: function () {
    return {
        isLoading: false,
        sumDisData:0.00,
        // sumNetData:0.00,
        listRQStoreData:[],  
        StatusQTSP: false, 
      }
  },
  mounted() {
      this.ListRQStore();
      this.CheckQTSP();
  },
  props: [
    "sumTotal",
    "isHaveStocks",
    "isHaveD",
    "listData",
    "verifyPrice",
    "allPO",
    "allRQ"
  ],
  methods: {
    sumDisReturn(para_val){
      this.sumDisData = para_val;
    },
    sumTOTtoLocaleString(para_price){
      return para_price.toLocaleString(undefined, {minimumFractionDigits: 2})
    },
    sumTheOneWithPrice(para_price){
      let getPoint = Math.ceil(para_price/50);
      return getPoint
    },
    sumNET(){
      let getSumNet = this.sumTotal - this.sumDisData;
      this.sumNetData =getSumNet;
    },
    async goCheckout(){
      this.isLoading = true
      await this.$emit("validatePrice");
      setTimeout(() => {
        this.verifyCondition() 
        this.isLoading = false
      }, 1000) 
      // await this.verifyCondition() 
    },
    async CheckQTSP() {
      let qtsp = this.getQTSP;
      this.StatusQTSP = qtsp != 0;
    },
    async verifyCondition() {
      // 
      if(this.getQTOF) {//Validate Order Form
        let verifyOrderForm = await this.verifyOrderFormPnl();
        if(!verifyOrderForm.passed) {
          serviceMain.showWarningAlert(this,'กรุณาระบุ PO/RQ ให้ครบถ้วน')
          return;
        }

        let verifyOrderFormProject = await this.verifyOrderFormProjectPnl();
        if(!verifyOrderFormProject.passed) {
          serviceMain.showWarningAlert(this,'กรุณาระบุ PO/RQ ให้ครบถ้วน')
          return;
        }
      }

      if (this.getQTSP > 0) {//Validate Salepersom
        // let verifySalePerson = await this.verifySalepersonPnl();
        // if(!verifySalePerson.passed) {
        //   serviceMain.showWarningAlert(this,'กรุณาระบุรหัสพนักงานให้ครบทุกรายการ')
        //   return;
        // }
      } else {
        await this.setSalePersonBySalesource()
      }
      let verifyRemark = await this.verifyRemark()
      if(!verifyRemark.passed) {
        serviceMain.showWarningAlert(this,'Remark มีตัวอักษรพิเศษ')
        return;
      }

      if(this.$store.getters['auth/getISTB']){
        this.setOrderformTB()
      }

      // await this.$emit("validatePrice");
      // console.log('D',this.verifyPrice.passed);
      if(!this.verifyPrice.passed) {
        serviceMain.showWarningAlert(this,'กรุณาระบุราคาสินค้าให้ครบถ้วน')
        return;
      }

      // if (!this.isHaveStocks && !this.isPro) {
      //   serviceMain.showWarningAlert(this,'สินค้าไม่มีในStock กรุณาเลือก OrderForm')
      //   return;
      // }

      let getflag = $( ".ckflagvfix" ).attr( "flag" );
      if(getflag=="true"){
        serviceMain.showWarningAlert(this, "มี bar ผสมกันไม่สามารถไปต่อได้")
        return
      }

      let data = {
          REF: this.getQTCUSTREF, 
          stcode: this.getQTDFSTORE,
      }
      let checkData = await serviceAPI.call_API('post',`cart/checkBeforePay`,data,'auth');
      let stok = checkData.data.dbitems[0].msg;

      if(stok.substring(0, 4) != 'OKOK') {
        serviceMain.showWarningAlert(this, stok.substring(5).replace(/\|/g,'\n'))
        return
      } 
      if(this.getQTOF){
        this.checkStockAlertOF()
      }else{
        this.$router.push('/CheckOut');
      }
    },
    async verifyOrderFormPnl() {
      return new Promise(resolve => {
        let [passed, porq] = [true, ''];
        $('.order-form-flag').each((e, obj) => {
          let ofFlag = $(obj).attr('offlag');
          if(ofFlag == '1') {
            let seq = $(obj).attr('seq');
            let porq = $(`#porq${seq}`).val();
            if(porq == '') {
              passed = false;
              return false;
            }

            let isPO = (porq == 'P');
            let venst = $(`#${isPO ? 'vendor' : 'rqstore'}${seq}`).val();
            if(!venst) {
              passed = false;
              return false;
            }
          }
        })

        resolve({
          passed,
          porq
        })
      })
    },
    async verifyOrderFormProjectPnl() {
      return new Promise(resolve => {
        let [passed, porq] = [true, ''];
        $('.order-form-project-flag').each((e, obj) => {
          let ofFlag = $(obj).attr('offlag');
          if(ofFlag == '1') {
            let projectid = $(obj).attr('projectid');
            let porq = $(`#porq-project-${projectid}`).val();
            if(porq == '') {
              passed = false;
              return false;
            }

            let isPO = (porq == 'P');
            let venst = $(`#${isPO ? 'vendor-project-' : 'rqstore-project-'}${projectid}`).val();
            if(!venst) {
              passed = false;
              return false;
            }
          }
        })

        resolve({
          passed,
          porq
        })
      })
    },
    async verifySalepersonPnl(){
      return new Promise(resolve => {
        let passed = true;
        $('.saleperson-val').each((e, obj) => {
          let salepersonVal = obj.value;
          if(salepersonVal == '') {
            passed = false;
            return false;
          }
        })

        resolve({
          passed: passed
        })
      })
    },
    async verifyRemark(){
      return new Promise(resolve => {
        let passed = true;
        $('.remarktext').each((e, obj) => {
          let remarkVal = obj.value;
          console.log(remarkVal);
          const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
          if(specialChars.test(remarkVal)) {
            passed = false;
            return false;
          }
        })

        resolve({
          passed: passed
        })
      })
    },
    async setSalePersonBySalesource(){
        let data = {
          REF: this.getQTCUSTREF, 
          SALEPERSON: '',
          QTSP: this.getQTSP, 
          SALESOURCE: this.getQTSALESOURCE
        }
        await serviceAPI.call_API('post',`cart/setSalePerson`,data,'auth');
    },
    async setOrderformTB(){
      try {
        let data={
          REF:this.getQTCUSTREF,
          OF_FLAG:'1' ,
          PORR:'R' , 
          VENST:this.$store.getters["service/getStcShowStock"]
        }
        await serviceAPI.call_API('post',`cart/setOrderform`,data,'auth');
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async ListRQStore(){
        try {
            let getAPI = await serviceAPI.call_API('post',`cart/ListRQStore`,{},'auth');
            this.listRQStoreData = getAPI.data.dbitems.map(itm => ({
                id: itm.STCODE,
                text: `${itm.STCODE} - ${itm.ST_SCODE}`
            }));
        } catch (error) {
            serviceMain.showErrorAlert(this,error.message)
        }
    },
    async goOTPREQ(){
      try {
        let _this = this
        this.$swal.fire({
            html: '<h2>ต้องการขอส่วนลดOTPหรือไม่?</h2>',
            showCancelButton: true,
            confirmButtonColor: this.getBuColor,
            confirmButtonText: 'ตกลง'
        }).then(async (result) => {
            if (result.value) {
              let sendRef = _this.getQTCUSTREF
              let data={
                  EMAIL:_this.getQTCUSTINFO.EMAIL , 
                  MOBILE:_this.getQTCUSTINFO.MOBILE , 
                  REF:_this.getQTCUSTREF
                }
              let getAPI = await serviceAPI.call_API('post',`otp/otpSaveQTsorder`,data,'auth');
              // let dataref = {
              //     "MCID": _this.$store.getters['customer/getQTMCID'],
              //     "EMAIL": _this.getQTCUSTINFO.EMAIL,
              //     "MOBILE": _this.getQTCUSTINFO.MOBILE ,
              //     "USERID": _this.$store.getters['auth/getQTUSERID']
              // }
              // let getAPIref = await serviceAPI.call_API('post', 'user/getCustref', dataref, 'auth');
              // let getData = getAPIref.data.dbitems[0];
              // let getCUSTREF = getData.CUSTREF;
              // _this.$store.dispatch('customer/setQTCUSTREF', getCUSTREF)
              // _this.$store.dispatch("customer/setQTDLVTRTYPE", "");
              // _this.qtShopItem = 0;
              console.log(`{ name: 'OTPRequest', params: { cartRef: ${_this.getQTCUSTREF} }, query: { stcode: ${_this.getQTDFSTORE} }}`);
              _this.$router.push({ name: 'OTPRequest', params: { cartRef: sendRef }, query: { stcode: _this.getQTDFSTORE }})
            }
        }).catch((error) => {
            serviceMain.showErrorAlert(this, error)
        });
        } catch (error) {
          serviceMain.showErrorAlert(this,error.message)
      }
    },
    async checkStockAlertOF(){
      try {
        // let _this = this
        // let msg = ''
        // if (this.listOverStock.length != 0) {
        //   msg = '<h2>รายการต่อไปนี้สินค้าในตะกร้ามากกว่าจำนวนในStockต้องการไปต่อหรือไม่?</h2>'
        //   for (let i = 0; i < this.listOverStock.length; i++) {
        //     msg = msg + `<br><span>${this.listOverStock[i]}</span>`
        //   }
        //   this.$swal.fire({
        //       html: msg,
        //       showCancelButton: true,
        //       confirmButtonColor: this.getBuColor,
        //       showDenyButton: true,
        //       showCancelButton: false,
        //       confirmButtonText: 'เปิด OF',
        //       denyButtonText: "ไม่เปิด OF",
        //   }).then(async (result) => {
        //       if (result.isDenied) {
        //         // console.log('CheckOut');
        //         this.$router.push('/CheckOut');
        //       }
        //   }).catch((error) => {
        //       serviceMain.showErrorAlert(this, error)
        //   });
        // }else{
        //   this.$router.push('/CheckOut');
        // }
        this.$router.push('/CheckOut');
      } catch (error) {
          this.isLoading = false
          serviceMain.showErrorAlert(this,error.message)
      }
    },
  },
  computed: {
    getQTOF:function(){
      return this.$store.getters['auth/getQTOF']
    },
    getQTSP:function(){
      return this.$store.getters['auth/getQTSP']
    },
    getQTSALESOURCE:function(){
      return this.$store.getters['auth/getQTSALESOURCE']
    },
    getQTCUSTREF: function () {
      return this.$store.getters["customer/getQTCUSTREF"];
    },
    getQTDFSTORE: function () {
      return this.$store.getters['service/getQTDFSTORE']
    },
    getQTCUSTINFO:function(){
      return this.$store.getters['customer/getQTCUSTINFO']
    },
    qtBu: function () {
      return this.$store.getters['customer/getQTBU'];
    },
    sumNetData: function () {
      return (this.sumTotal - this.sumDisData) || 0;
    },
    qtShopItem: {
      get() {
        return this.$store.getters["customer/getQTSHOPITEM"];
      },
      set(value) {
        this.$store.dispatch("customer/setQTSHOPITEM", value);
      },
    },
    isPro:function(){
        return process.env.NODE_ENV == 'production'
    },
    listOverStock:function(){
      let list = []
      for (let i = 0; i < this.listData.length; i++) {
        if((this.listData[i].QNT > this.listData[i].STOCKAVAIL) && this.listData[i].OFflag != 1){
          list.push(this.listData[i].PRN)
        }
      }
      return list
    },
  },
  watch: {
    sumTotal:function(){
      this.$refs.PlppCartRef.showPLPP()
      // setTimeout(()=> this.$refs.PlppCartRef.showPLPP() , 200);
    },

    
  },

}

</script>

<style scoped>
  .card-white {
    position: relative;
    background-color: white;
    border: none;
    border-radius: 8px;
    padding: 15px;
    margin: 0px 0px 10px 0px;
  }
  .head-table th{
    font-family: 'kittithadabold75regular' !important;
  }
  .line-height {
    line-height: 18px !important;
  }

/* css priceloading */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 30px;
}
.lds-ellipsis span {
  position: absolute;
  top: 12px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #55246c;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis span:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis span:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis span:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis span:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
.skeletoncart-box {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
}
.skeletoncart-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 2s infinite;
  content: '';
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/* End css priceloading */
</style>



// ALC: 0
// ALOCATE: 0
// AMT: "96.00"
// ATBCODE: "  "
// AUTOPLPP: 0
// COST: "82.80"
// CTLID: 8894900
// CanEditUPC: "N"
// EDT: ""
// FREENOSTOCK: false
// OFflag: 0
// PCD: "0000041781616"
// PREORDER: 0
// PRN: "ท่อ PVC ชั้น 5 น้ำไทย ขนาด 1 1/2 นิ้ว x 4 ม. สีฟ้า"
// PRSTATUS: "A"
// QNT: 1
// QNTAVAIL: 79
// RECID: 4317
// REF: "QT2105000014"
// REM: "Mรวม/0202 (Instore Promotion_All Store) ซื้อสินค้าใน TWD และ BNB ครบทุกๆ 3,000 บาท แลกซื้อชุดราง Led OPPLE ราคา 108 บาท (5 สิทธิ์/1 ใบเสร็จ)"
// SEQ: 1
// SERVICEFLAG: false
// SERVICEGROUP: " "
// SERVICENAME: ""
// SKCODE: 60180854
// STAT: "ยังไม่ยืนยัน\t"
// STATUS: " "
// STCODE: 920
// STOCKAVAIL: 79
// TOT: 96
// TSPGROUP: 2
// TTM: "2021-05-17T09:44:20.983Z"
// UPC: "96.00"
// WEIGHT: 1.25
// pckloc: 2
// remark: "Mรวม/0202 (Instore Promotion_All Store) ซื้อสินค้าใน TWD และ BNB ครบทุกๆ 3,000 บาท แลกซื้อชุดราง Led OPPLE ราคา 108 บาท (5 สิทธิ์/1 ใบเสร็จ)"
// slugname: "ท่อ PVC ชั้น 5 น้ำไทย ขนาด 1 1/2 นิ้ว x 4 ม. สีฟ้า"
