<template>
<section>
    <div style='text-align:center;'>
        <br>
        <img :src="`${qtBu == 'bnb' ? '/img/notfound.gif' : '/img/emptycart.png'}`" style="max-height:70vh; width:60%;max-width:500px;"><br>
        ตะกร้าสินค้า ว่างอยู่ กลับไป Scanสินค้า หรือ ค้นหาสินค้า
        <router-link to="/" :class="`twdorbnb btn-${qtBu}`" >
            <span><i class="fas fa-search mt-1" style="color:white;"></i> กลับไปค้นหาสินค้า</span>
        </router-link>
    </div>
</section>
</template>

<script>
export default {
    name: "CartFullNotFoundProduct",
    data: function () {
        return {

        }
    },
    computed: {
        qtBu: function () {
            return this.$store.getters['customer/getQTBU'];
        }
    }
}
</script>

<style>

</style>
