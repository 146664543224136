<template>
  <section>
    <button @click="openModal" type="button" class="btn-PWB-nocolor bg-black font-kitti-medium my-1`">
      <slot>เช็คสต็อก</slot>
    </button>
    <Modal ref="CheckStockAllCartRef" size="90">

      <template v-slot:header>
        <p>เช็คสต็อกตะกร้าสินค้า</p>
      </template>
      <template v-slot:body>
        <div class="row px-3 m-0 mt-3">
          <div class="col-12 mb-2">
            <!-- <Multiselect v-model="selected" :options="options" mode="tags" :searchable="true" :createTag="true"
              placeholder="เลือกสาขา" /> -->

            <Multiselect
              v-model="selected"
              mode="tags"
              :closeOnSelect="false"
              :searchable="true"
              :groups="true"
              :groupSelect="true"
              :options="options"
              placeholder="เลือกสาขา"
            >
              <!-- <template v-slot:option="{ option }">
                <input type="checkbox" id="checkbox" v-model="option.checked"> {{ option.name }}
              </template> -->
            </Multiselect>

            <div class="row m-3" style="min-height:200px">
              <div class="col-12 card-white" style="text-align:center; overflow:auto;">
                <table id="allCartStockTable" class="table table-striped table-bordered m-0 p-0"
                  style="width:100%;">
                  <thead>
                    <tr>
                      <th v-for="(keyItem, index) in keysTemp" :key="index">{{keyItem}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(values, index) in valuesTemp" :key="index">
                      <td v-for="(val, index) in values" :key="index">{{val}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row m-1" style="justify-content: flex-end;">
              <button type="button" class="btn btn-primary m-1" style="border: 1px solid blue"
                @click="selectStore(selected)">ค้นหา</button>
              <button type="button" class="btn btn-secondary m-1" @click="hideModal"
                style="border: 1px solid black">ปิด</button>
            </div>

          </div>


        </div>

      </template>
    </Modal>
  </section>
</template>

<script>
  import * as serviceAPI from '@/services/API.service';
  import * as serviceMain from '@/services/main.service';
  import Modal from '@/components/Modal.vue'
  import Multiselect from '@vueform/multiselect'

  import 'jquery/dist/jquery.min.js';
  import "datatables.net-dt/js/dataTables.dataTables"
  import "datatables.net-dt/css/jquery.dataTables.min.css"
  import $ from 'jquery';
  export default {
    name: "CheckStockAllCart",
    data:function() {
      return {
        selected: [],
        options: [],
        listStore: [],
        storeStock: [],
        listData: [],
        keysTemp: [],
        valuesTemp: [],
        customTable: {
          "order": [[ 5, "desc" ]], 
          scrollX: true,
        },
        tempObj:[],

      };
    },
    components: {
      Modal,
      Multiselect
    },
    mounted() {
    },

    methods: {
      async openModal() {
        try {
          this.$refs.CheckStockAllCartRef.showmodal()
          this.onModal()
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async hideModal() {
        try {
          this.tempObj = [];
          this.$refs.CheckStockAllCartRef.hidemodal()
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async onModal() {
        try {
          //ฟังชั่นทำงานเมื่อเปิด modal ให้โหลดข้อมูล จาก api
          this.startStore();
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async startStore() {
        let getQTSAVESTORE = this.$store.getters['service/getQTSAVESTORE']
        let locationsTEMP = getQTSAVESTORE.split('|');
        let listTemp = locationsTEMP.map(e => e.split(','));
        listTemp.forEach((itm, idx, arr) => {
          this.listStore = itm.slice(0, 2)
          // let Temp = `${this.listStore[1]} - ${this.listStore[0]}`;
          // this.tempObj.push(Temp)
          let Temp = `${this.listStore[1]} - ${this.listStore[0]}`;
          this.options.push(Temp)
        })
        // this.options[0].options = this.tempObj
      },
      async selectStore(para_selected) {
        this.storeStock = [];
        para_selected.forEach((itm, idx, arr) => {
          let dataProc = itm.slice(itm.length - 3, itm.length);
          this.storeStock.push(dataProc)
        });
        try {
          let data = {
            "REF": this.CartRef,
            "stcode": this.StoreCode,
            "storelist": this.storeStock,
            /////////////////Force Data///////////////////            
            // "ref": "QT2108000003",
            // "stcode": '920',
            // "storelist": ["017", "920"]
          }
          this.valuesTemp.length = 0;
          let getAPI = await serviceAPI.call_API('post', `cart/OrderProductList`, data, 'auth');
          let getData = getAPI.data.dbitems

          for(let i of getData) {
            let delArr = Object.keys(i).filter(e => e.startsWith("POS"));
            delArr.every(e => delete i[e]);
          }
          this.listData = getData;
          let header = Object.keys(this.listData[0])
          this.keysTemp = header;

          getData.forEach((itm, idx, arr) => {
            let valuetable = Object.values(itm)
            this.valuesTemp.push(valuetable)
          })

        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }

      },
      async setDataTable(){
        $('#allCartStockTable').DataTable(this.customTable);
      }
    },
    computed: {
      getQTSAVESTORE: function () {
        return this.$store.getters['service/getQTSAVESTORE']
      },
      CartRef: function () {
        return this.$store.getters['customer/getQTCUSTREF']
      },
      StoreCode: function () {
        return this.$store.getters['service/getQTDFSTORE']
      }
    },
    watch: {
      getQTSAVESTORE: function () {
          this.startStore()
      },
      valuesTemp: function() {
        this.$nextTick(() => {
          this.setDataTable();
        })
      }
    }
  };
</script>

<style scoped lang="scss">
  .input-History {
    border: 1px gray solid;
    padding: 0px 15px;
    border-radius: 4px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  table.dataTable tbody th,
  table.dataTable tbody td {
    padding: 8px 10px;
    min-width: 150px;
  }
</style>
