<template>
    
  <div class="row mt-2 mb-1">
    <div class="col-4">
      <button v-if="cartData.OFflag" :offlag="cartData.OFflag" :projectid="cartData.PROJECT_ID" @click="off_orderform(cartData)" class="btn-visible-black order-form-project-flag">cancel orderform</button>
      <button v-else :offlag="cartData.OFflag" :projectid="cartData.PROJECT_ID" @click="on_orderform(cartData)" class="btn-visible-black order-form-project-flag">orderform</button>
    </div>
    <div class="col-4">
      <select @change="suggestPORQ(cartData)" v-if="cartData.OFflag" v-model="cartData.PORQ" :id="`porq-project-${cartData.PROJECT_ID}`" class='form-control'>
        <option value=''>กรุณาระบุ PO/RQ</option>
        <option value='P'>PO</option>
        <option value='R'>RQ</option>
      </select>
    </div>
    <div class="col-4">
      <input @change="CheckOFVENST(cartData)" v-show="cartData.PORQ == 'P'" v-model="cartData.VENST" type="text" class="text-center w-100 form-control" :id="`vendor-project-${cartData.PROJECT_ID}`" placeholder="ระบุเลข vender">
      <Select2 @select="CheckOFVENST(cartData)" v-show="cartData.PORQ == 'R'" v-model="cartData.VENST" :options="listRQStoreData" :settings="select2Settings" :id="`rqstore-project-${cartData.PROJECT_ID}`"/>
    </div>
  </div>
               
</template>


<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
import Select2 from 'vue3-select2-component';
import mixin from '@/mixins/mixin.js'
export default {
  name: 'OrderForm',
  components: {
    Select2
  },
  data: function () {
    return {
      select2Settings: {
        placeholder: "ระบุ Store 5 หลัก", 
        width: "100%"
      }
    }
  },
  mixins: [mixin],
  mounted() {
    console.log(this.cartData)
  },
  props: [
    "cartData",
    "listRQStoreData"
  ],
  methods: {
    async on_orderform(e){
      try {
        e.OFflag=1
        e.PORQ=''
        e.VENST=''
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async off_orderform(e){
      try {
        e.OFflag=0
        e.PORQ=''
        e.VENST=''
        // $(`#vendor-project-${e.SEQ}`).css('display', 'none');
        // $(`#rqstore${e.SEQ}`).next().hide();
        let data={
          REF:this.CartRef,
          SEQ:null ,
          OF_FLAG:e.OFflag ,
          PORR:e.PORQ , 
          VENST:e.VENST,
          PROJECT_ID: e.PROJECT_ID
        }
        await serviceAPI.call_API('post',`cart/setOrderformBySeq`,data,'auth');
        this.$store.dispatch("service/setReloadCart");
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async suggestPORQ(e){
      try {
        if(e.PORQ=='P'){
          e.VENST =e.SPCODE.trim()
          // $(`#vendor${e.SEQ}`).css('display', 'unset');
          // $(`#rqstore${e.SEQ}`).next().hide();
          if(e.VENST){
            this.CheckOFVENST(e)
          }
        }else{
          // $(`#vendor${e.SEQ}`).css('display', 'none');
          // $(`#rqstore${e.SEQ}`).next().show();
          e.VENST=''
        }
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async CheckOFVENST(e){
      try {
        let data={
          PORR:e.PORQ , 
          VENST:e.VENST
        }
        await serviceAPI.call_API('post',`cart/CheckOFVENST`,data,'auth');
        this.setOrderformBySeq(e)
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    async setOrderformBySeq(e){
      try {
        let data={
          REF:this.CartRef,
          SEQ:null,
          OF_FLAG:e.OFflag ,
          PORR:e.PORQ , 
          VENST:e.VENST,
          PROJECT_ID: e.PROJECT_ID
        }
        await serviceAPI.call_API('post',`cart/setOrderformBySeq`,data,'auth');
        this.$store.dispatch("service/setReloadCart");
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
  },
  computed: {
    CartRef:function(){
      return this.$store.getters['customer/getQTCUSTREF']
    },
    qtSalesource: function() {
      return this.$store.getters['auth/getQTSALESOURCE']
    }
  },
  watch: {
  }
}

</script>

<style scoped>
  .form-control{
    height: 33px !important;
    padding: 0.1rem 0.75rem !important;
  }
</style>



// ALC: 0
// ALOCATE: 0
// AMT: "96.00"
// ATBCODE: "  "
// AUTOPLPP: 0
// COST: "82.80"
// CTLID: 8894900
// CanEditUPC: "N"
// EDT: ""
// FREENOSTOCK: false
// OFflag: 0
// PCD: "0000041781616"
// PREORDER: 0
// PRN: "ท่อ PVC ชั้น 5 น้ำไทย ขนาด 1 1/2 นิ้ว x 4 ม. สีฟ้า"
// PRSTATUS: "A"
// QNT: 1
// QNTAVAIL: 79
// RECID: 4317
// REF: "QT2105000014"
// REM: "Mรวม/0202 (Instore Promotion_All Store) ซื้อสินค้าใน TWD และ BNB ครบทุกๆ 3,000 บาท แลกซื้อชุดราง Led OPPLE ราคา 108 บาท (5 สิทธิ์/1 ใบเสร็จ)"
// SEQ: 1
// SERVICEFLAG: false
// SERVICEGROUP: " "
// SERVICENAME: ""
// SKCODE: 60180854
// STAT: "ยังไม่ยืนยัน\t"
// STATUS: " "
// STCODE: 920
// STOCKAVAIL: 79
// TOT: 96
// TSPGROUP: 2
// TTM: "2021-05-17T09:44:20.983Z"
// UPC: "96.00"
// WEIGHT: 1.25
// pckloc: 2
// remark: "Mรวม/0202 (Instore Promotion_All Store) ซื้อสินค้าใน TWD และ BNB ครบทุกๆ 3,000 บาท แลกซื้อชุดราง Led OPPLE ราคา 108 บาท (5 สิทธิ์/1 ใบเสร็จ)"
// slugname: "ท่อ PVC ชั้น 5 น้ำไทย ขนาด 1 1/2 นิ้ว x 4 ม. สีฟ้า"
