<template>
  <!-- <ImageData > -->
  <section class="px-2 py-0">
    <div v-if="!isLoading" class="row" :key="listData">
      <ProdboardCart
        :cartData="listData"
        :CartRef="CartRef"
        :StoreCode="StoreCode"
        :qtBu="qtBu"
        :listRQStoreData="listRQStoreData"
      />
      <div v-for="(e, index) in listData" :key="index" class="col-12">
        <div v-if="e.PROJECT_ID == null" class="row">
          <div class="col-12 cardcart">
            <span :class="`close-circle-${qtBu}`" @click="deleteItem(e)"
              >X</span
            >
            <div class="row">
              <div class="col-12 col-md-3" pagco="^slugname^">
                <router-link
                  :to="
                    `/ProductDetail/${replaceSlugName(e.slugname)}-${e.SKCODE}`
                  "
                >
                  <ImageBySKU class="w-100" :sku="e.SKCODE" />
                </router-link>
              </div>
              <div class="col-12 col-md-9">
                <span v-if="e.CanEditUPC == 'Y'" class="font-weight-bold">
                  <textarea
                    @change="changePriceCostName(e)"
                    class="changeNameText"
                    type="text"
                    v-model="e.PRN"
                  ></textarea
                ></span>
                <span v-else class="font-900 font-weight-bold">{{ e.PRN }}</span
                ><br />
                <span
                  ><router-link
                    :to="
                      `/ProductDetail/${replaceSlugName(e.slugname)}-${
                        e.SKCODE
                      }`
                    "
                    >Show Detail</router-link
                  ></span
                >
                <br />
                <span class="font-gray font-weight-bold"
                  >รหัสสินค้า: {{ e.SKCODE }}</span
                >
                <!-- <span v-if="e.CanEditUPC=='N'" class="font-gray font-weight-bold"> ราคา: {{e.UPC}} บาท</span> -->
                <span
                  v-if="e.CanEditUPC == 'Y'"
                  class="font-gray font-weight-bold"
                >
                  ราคา: ฿
                  <input
                    @change="changePriceCostName(e)"
                    class="changePrice"
                    type="number"
                    v-model="e.NEWUPC"
                  />
                  บาท</span
                >
                <span v-else class="font-gray font-weight-bold">
                  ราคา: {{ e.UPC }} บาท</span
                >
                <span v-if="isShowCost" class="font-gray font-weight-bold">
                  ราคาทุน:
                  <input
                    @change="changePriceCostName(e)"
                    class="changePrice"
                    type="number"
                    v-model="e.NEWCOST"
                  />
                  บาท</span
                >
                <br />
                <span class="font-weight-bold">จำนวน </span>
                <span class="box-qty">
                  <span @click="minus(e)" class="text-center minus pointer"
                    >-</span
                  >
                  <input
                    @change="changeQNT(e)"
                    class="text-center input-qty"
                    type="number"
                    v-model="e.QNT"
                  />
                  <span @click="plus(e)" class="text-center plus pointer"
                    >+</span
                  >
                </span>
                <br />
                <div
                  v-if="!$store.getters['auth/getISB2B']"
                  class="d-flex justify-content-between"
                >
                  <span class="font-weight-bold"
                    >Stock: {{ e.STOCKAVAIL }}</span
                  >
                  <span class="font-weight-bold"
                    >Stock DC: {{ e.STOCKDC }}</span
                  >
                  <span class="font-weight-bold">Status: {{ e.PRSTATUS }}</span>

                  <span v-if="e.loading" class="lds-ellipsis"
                    ><span :class="`lds-ellipsis-span-${qtBu}`"></span
                    ><span :class="`lds-ellipsis-span-${qtBu}`"></span
                    ><span :class="`lds-ellipsis-span-${qtBu}`"></span
                    ><span :class="`lds-ellipsis-span-${qtBu}`"></span
                  ></span>
                  <span v-else :class="` price-${qtBu}`"
                    >฿ {{ priceBath(e.TOT) }}</span
                  >
                </div>
                <div v-else class="d-flex justify-content-end">
                  <span :class="` price-${qtBu}`"
                    >฿ {{ priceBath(e.TOT) }}</span
                  >
                </div>
                <div v-if="!$store.getters['auth/getISB2B']">
                  <OrderForm
                    v-if="getQTOF && listRQStoreData"
                    :listRQStoreData="listRQStoreData"
                    :cartData="e"
                  />
                  <SalePerson v-if="StatusQTSP" :cartData="e" />

                  <div class="row">
                    <div class="col-12">
                      <label
                        class="ckflagvfix"
                        :flag="e.SERVICEFLAG"
                        style="font-family: 'kittithadabold75regular'; font-size: 24px; color: green;"
                        >{{ e.SERVICENAME }}</label
                      >
                    </div>
                  </div>
                  <span class="font-weight-bold">แนะนำโปรโมชั่น: </span>
                  <span class="font-weight-normal">{{ e.remark }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div
        v-for="index in 4"
        :key="index"
        class="col-12 fillpag"
        style="padding: 5px 5px"
      >
        <div class="shadowDiv" style="overflow: hidden">
          <div class="row">
            <div class="col-12 col-md-3">
              <span class="skeleton-box w-100" style="height: 150px"></span>
            </div>
            <div class="col-12 col-md-9">
              <h3 class="blog-post__headline">
                <span class="skeleton-box" style="width: 55%"></span>
              </h3>
              <p class="m-0">
                <span class="skeleton-box" style="width: 80%"></span>
                <span class="skeleton-box" style="width: 90%"></span>
                <span class="skeleton-box" style="width: 83%"></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import * as serviceAPI from "@/services/API.service";
  import * as serviceMain from "@/services/main.service";
  import ImageBySKU from "@/components/ImageBySKU.vue";
  import OrderForm from "@/components/Cart/OrderForm.vue";
  import SalePerson from "@/components/Cart/SalePerson.vue";
  import ProdboardCart from "@/components/Cart/ProdboardCart.vue";
  export default {
    name: "ListCart",
    components: {
      ImageBySKU,
      OrderForm,
      SalePerson,
      ProdboardCart,
    },
    data: function() {
      return {
        listData: [],
        isLoading: true,
        sumTOTData: "",
        sumCountData: 0,
        StatusQTSP: false,
        listRQStoreData: [],
      };
    },
    mounted() {
      // this.showListCart();
      this.ListRQStore();
      this.$emit("isHaveStock", this.isHaveStock);
    },
    props: [],
    methods: {
      async CheckQTSP() {
        let qtsp = this.getQTSP;
        this.StatusQTSP = qtsp != 0;
      },

      async showListCart() {
        try {
          setTimeout(async () => {
            if (!this.$store.getters["auth/getISB2B"]) {
              let data = {
                REF: this.CartRef,
              };
              let getAPI = await serviceAPI.call_API(
                "post",
                `cart/getcart`,
                data,
                "auth"
              );
              this.listData = getAPI.data.dbitems.map((e) => ({
                ...e,
                NEWUPC: e.UPC.replace(/,/g, ""),
                NEWCOST: e.COST.replace(/,/g, ""),
              }));
              this.isLoading = false;
              this.CheckQTSP();
              this.sumTOT();
              this.getSumCount();
            } else {
              let data = {
                REF: this.CartRef,
                UUID: this.getB2BUUID,
              };
              let getAPI = await serviceAPI.call_API(
                "post",
                `b2b/cart/getcart`,
                data,
                "auth"
              );
              this.listData = getAPI.data.dbitems.map((e) => ({
                ...e,
                NEWUPC: e.UPC.replace(/,/g, ""),
                NEWCOST: e.COST.replace(/,/g, ""),
              }));
              this.isLoading = false;
              this.sumTOT();
              this.getSumCount();
            }
          }, 500);
          // this.calPLPP()
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      replaceSlugName(slugname) {
        return slugname
          .replace(/ /g, "-")
          .replace(/\//g, "|")
          .replace(/#/g, "")
          .replace(/"/g, "")
          .replace(/'/g, "")
          .replace(/\?/g, "")
          .replace(/\*/g, "")
          .replace(/\+/g, "")
          .replace(/\:/g, "")
          .replace(/\./g, "");
      },
      async sumTOT() {
        try {
          let getSumTOT = 0;
          for (let e of this.listData) {
            getSumTOT = getSumTOT + e.TOT;
          }
          this.sumTOTData = getSumTOT;
          this.$emit("sumTotal", this.sumTOTData);
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },

      async changePriceCostName(e) {
        try {
          let data = {
            REF: this.CartRef,
            QNT: e.QNT,
            UPC: e.NEWUPC,
            SEQ: e.SEQ,
            PRN: e.PRN,
            COST: e.NEWCOST,
          };
          let getAPI = await serviceAPI.call_API(
            "post",
            `cart/changePriceCostName`,
            data,
            "auth"
          );

          this.$store.dispatch("service/setReloadCart");
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async deleteItem(e) {
        e.QNT = 0;
        this.changeQNT(e);
      },
      async minus(e) {
        if (e.QNT <= 1) {
          e.QNT = 1;
        } else {
          e.QNT -= 1;
        }
        this.changeQNT(e);
      },
      async plus(e) {
        e.QNT += 1;
        this.changeQNT(e);
      },
      async changeQNT(e) {
        try {
          if (e.QNT > 999999) {
            e.QNT = 999999;
          }

          e.loading = true;
          let getCartRef = this.CartRef;

          if (!this.$store.getters["auth/getISB2B"]) {
            let data = {
              REF: getCartRef,
              QNT: e.QNT,
              SEQ: e.SEQ,
            };
            await serviceAPI.call_API("post", `cart/changeQNT`, data, "auth");
          } else {
            let data = {
              REF: getCartRef,
              QNT: e.QNT,
              SEQ: e.SEQ,
              UUID: this.getB2BUUID,
            };
            await serviceAPI.call_API(
              "post",
              `b2b/cart/changeQNT`,
              data,
              "auth"
            );
          }

          this.$store.dispatch("service/setReloadCart");
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      priceBath(e) {
        return e.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        });
      },
      getSumCount() {
        let getSumCount = 0;
        for (let e of this.listData) {
          getSumCount = getSumCount + e.QNT;
        }
        this.sumCountData = getSumCount;
        this.$store.dispatch("customer/setQTSHOPITEM", getSumCount);
      },
      async ListRQStore() {
        try {
          let getAPI = await serviceAPI.call_API(
            "post",
            `cart/ListRQStore`,
            {},
            "auth"
          );
          this.listRQStoreData = getAPI.data.dbitems.map((itm) => ({
            id: itm.STCODE,
            text: `${itm.STCODE} - ${itm.ST_SCODE}`,
          }));
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
    },
    computed: {
      getB2BUUID: function() {
        return this.$store.getters["auth/getB2BUUID"];
      },
      CartRef: function() {
        if (this.$store.getters["auth/getISB2B"]) {
          return this.$store.getters["auth/getB2BCUSTREF"];
        } else {
          return this.$store.getters["customer/getQTCUSTREF"];
        }
      },
      StoreCode: function() {
        return this.$store.getters["service/getQTDFSTORE"];
      },
      ReloadCart: function() {
        return this.$store.getters["service/getReloadCart"];
      },
      getQTSP: function() {
        return this.$store.getters["auth/getQTSP"];
      },
      getQTOF: function() {
        return this.$store.getters["auth/getQTOF"];
      },
      qtBu: function() {
        return this.$store.getters["customer/getQTBU"];
      },
      getQTPROFILE: function() {
        return this.$store.getters["auth/getQTPROFILE"];
      },
      isShowCost: function() {
        let isShowCost = false;
        let profile = this.getQTPROFILE.split("X");
        if (profile.includes("0077")) {
          isShowCost = true;
        }
        return isShowCost;
      },
      isHaveStock: function() {
        for (let i = 0; i < this.listData.length; i++) {
          if (
            this.listData[i].STOCKAVAIL <= 0 &&
            this.listData[i].PORQ.trim() == ""
          ) {
            return false;
          }
        }
        return true;
      },
    },
    watch: {
      StoreCode: function() {
        this.showListCart();
      },
      ReloadCart: function() {
        this.isLoading = true;
        this.showListCart();
      },
      isHaveStock: function() {
        this.$emit("isHaveStock", this.isHaveStock);
      },
    },
  };
</script>

<style lang="scss" scoped>
  $color-PWB: #55246c;
  $color-bnb: #006e63;
  $color-at1: #3866af;

  .cardcart {
    position: relative;
    background-color: white;
    border: none;
    border-radius: 8px;
    padding: 15px;
    margin: 0px 0px 10px 0px;
  }

  .shadowDiv {
    margin: 0px;
    background-color: white;
    padding: 10px;
    border-radius: 7px;
    height: auto;
  }

  .font-weight-bold {
    font-family: "kittithadabold75regular";
  }

  .minus {
    white-space: nowrap;
    width: 20px;
    height: 24px;
    border: 1px gray solid;
    border-radius: 4px 0px 0px 4px;
    padding: 2px 4px 1px 4px;
    display: inline;
    vertical-align: middle;
    text-align: right;
    font-size: 18px;
    font-weight: bold;
    z-index: 998;
  }

  .plus {
    width: 20px;
    height: 24px;
    border: 1px gray solid;
    border-radius: 0px 4px 4px 0px;
    padding: 2px 4px 1px 4px;
    display: inline;
    vertical-align: middle;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
  }

  .input-qty {
    height: 24px;
    font-weight: bold;
    width: 50px;
    text-align: center;
    font-size: 20px;
    border: 1px gray solid;

    vertical-align: middle;
    margin: 0;
  }

  .inputEdit {
    min-width: 90px;
  }

  .btn-qty {
    border: 1px gray solid;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  @mixin close-circle($color) {
    font-size: 15px;
    color: rgb(255, 255, 255);
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.025em;
    border-radius: 50%;
    background-color: $color;
    width: 26px;
    height: 26px;
    line-height: 26px;
    position: absolute;
    transition: all 0.2s ease-out;
    top: -10px;
    left: -10px;
    z-index: 2;
  }

  .close-circle-PWB {
    @include close-circle($color-PWB);
  }

  .close-circle-bnb {
    @include close-circle($color-bnb);
  }

  .close-circle-at1 {
    @include close-circle($color-at1);
  }

  /* css priceloading */
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 30px;
  }

  .lds-ellipsis span {
    position: absolute;
    top: 12px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #55246c;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .lds-ellipsis span:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  .lds-ellipsis span:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis span:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis span:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(24px, 0);
    }
  }

  a {
    color: red;
    font-weight: bold;
  }

  a:hover {
    color: black;
  }

  .changeNameText {
    display: inline;
    width: 100%;
    height: 1.8rem;
    padding: 5px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: black;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .changePrice {
    display: inline;
    width: 20%;
    height: 30px;
    padding: 5px;
    text-align: left;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: gray;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid gray;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  /* End css priceloading */
</style>

// ALC: 0 // ALOCATE: 0 // AMT: "96.00" // ATBCODE: " " // AUTOPLPP: 0 // COST:
"82.80" // CTLID: 8894900 // CanEditUPC: "N" // EDT: "" // FREENOSTOCK: false //
OFflag: 0 // PCD: "0000041781616" // PREORDER: 0 // PRN: "ท่อ PVC ชั้น 5 น้ำไทย
ขนาด 1 1/2 นิ้ว x 4 ม. สีฟ้า" // PRSTATUS: "A" // QNT: 1 // QNTAVAIL: 79 //
RECID: 4317 // REF: "QT2105000014" // REM: "Mรวม/0202 (Instore Promotion_All
Store) ซื้อสินค้าใน TWD และ BNB ครบทุกๆ 3,000 บาท แลกซื้อชุดราง Led OPPLE ราคา
108 บาท (5 สิทธิ์/1 ใบเสร็จ)" // SEQ: 1 // SERVICEFLAG: false // SERVICEGROUP: "
" // SERVICENAME: "" // SKCODE: 60180854 // STAT: "ยังไม่ยืนยัน\t" // STATUS: "
" // STCODE: 920 // STOCKAVAIL: 79 // TOT: 96 // TSPGROUP: 2 // TTM:
"2021-05-17T09:44:20.983Z" // UPC: "96.00" // WEIGHT: 1.25 // pckloc: 2 //
remark: "Mรวม/0202 (Instore Promotion_All Store) ซื้อสินค้าใน TWD และ BNB
ครบทุกๆ 3,000 บาท แลกซื้อชุดราง Led OPPLE ราคา 108 บาท (5 สิทธิ์/1 ใบเสร็จ)" //
slugname: "ท่อ PVC ชั้น 5 น้ำไทย ขนาด 1 1/2 นิ้ว x 4 ม. สีฟ้า"
